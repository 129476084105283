/* How we work */

.howwework-section {
  padding: 10rem 0 3rem;

  .howwework-header {
    h2 {
      font-family: var(--font-bold);
      font-size: 4.8rem;
      text-align: center;
      margin-bottom: 1.25rem;
    }

    h4 {
      font-family: var(--font-medium);
      font-size: 2.4rem;
      text-align: center;
      margin: 0;
    }

    h6 {
      font-size: 1.6rem;
      text-align: center;
      max-width: 70%;
      margin: 2rem auto;
    }
  }

  .howwework-method-wrap {
    margin: 5rem 0 2rem;
  }

  .howwework-method-wrap h4 {
    font-family: var(--font-medium);
    font-size: 2.4rem;
    text-align: center;
    border-bottom: 1px solid var(--light-75);
    padding-bottom: 1.5rem;
    margin: 0;
  }

  .howwework-method-wrap .hwk-circle-wrap {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 80%;
    overflow: hidden;
    isolation: isolate;
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle {
    display: flex;
    align-items: center;
    --dimension: 56.8rem;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    padding: 8rem;
    width: var(--dimension);
    height: var(--dimension);
    border-radius: 50%;
    border: 1px solid var(--light-75);
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle h5 {
    font-size: 1.4rem;
    transform: translateY(calc(100% + 2.5rem));
    text-align: center;
    line-height: 1.8;
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 0;
    max-width: 90%;
    margin: 0 auto;
  }

  .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item:first-child {
    transform: translateX(-0.25rem);
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row .hwk-item:last-child {
    transform: translateX(4.8rem);
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row .hwk-item {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item.active
    .hwk-img {
    background: var(--purple);
    transition: 450ms all ease-out;
  }

  .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item.active
    .hwk-img
    svg
    > * {
    fill: var(--white) !important;
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row .hwk-item .hwk-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    min-width: 3.2rem;
    min-height: 3.2rem;
    max-width: 3.2rem;
    max-height: 3.2rem;
    background: var(--white);
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row .hwk-item h5 {
    font-family: var(--font-medium);
    font-size: 1.6rem;
    margin: 0;
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row.second {
    max-width: 80%;
  }

  .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row.second
    .hwk-item:first-child {
    transform: translateX(1.25rem);
  }

  .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row.second
    .hwk-item:last-child {
    transform: translateX(-0.75rem);
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row.third {
    max-width: 70%;
    padding: 0;
    justify-content: center;
  }

  .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row.third .hwk-item {
    flex-direction: column;
    margin-top: 0.25rem;
    transform: translateX(0);
  }

  /* Tech Stack */

  .hww-tech-stack-section {
    padding: 10rem 0 5rem;

    h4 {
      font-family: var(--font-bold);
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }
    h5 {
      font-family: var(--font-regular);
      font-size: 1.6rem;
      max-width: 55%;
      line-height: 1.65;
    }

    #techStackAccordion {
      padding: 3rem 0 0;

      .accordion-item {
        border: none;
        border-bottom: 1px solid var(--light-ee);

        &:last-child {
          border-bottom: none;
        }

        .accordion-header button {
          border: none !important;
          background: none;
          padding: 1.5rem 0;
          box-shadow: none;

          &:not(.collapsed) {
            color: var(--purple);
            border: none !important;
          }

          &::after {
            display: none;
          }
        }

        .accordion-body {
          padding: 1rem 0;
          border: none;
        }

        .accordion-collapse.collapse {
          transition: 0.2s ease;
        }

        .accordion-collapse.collapse.show .accordion-body {
          border-bottom: 2px solid var(--purple);
        }
      }

      h2 button {
        font-size: 1.6rem;
      }

      .hwwt-text {
        margin: 0.5rem 0 2rem;

        h6 {
          font-family: var(--font-medium);
          font-size: 1.4rem;
        }

        p {
          font-size: 1.25rem;
        }
      }
    }

    .tech-stack-logos-wrap {
      display: grid;
      place-content: center;
      grid-template-columns: repeat(auto-fit, minmax(12%, 1fr));
      gap: 3.5rem;
      padding: 0 5rem;
      margin: 8rem 0;

      .tsl-item {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.6rem;
        height: 4.6rem;
        min-width: 4.6rem;
        min-height: 4.6rem;
        max-width: 4.6rem;
        max-height: 4.6rem;
        transform: scale3d(1, 1, 1);
        transition: 300ms transform ease;

        &:hover {
          transform: scale3d(1.2, 1.2, 1.2);
        }

        &::before {
          transition-delay: 350ms;
          transition: 350ms ease;
        }

        &:hover::before {
          content: attr(data-content);
          position: absolute;
          top: calc(100% + 0.25rem);
          left: 50%;
          z-index: 2;
          transform: translateX(-50%);
          font-family: var(--font-bold);
          font-size: 1.15rem;
          background: var(--black-21);
          box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.1);
          min-width: max-content;
          padding: 0.25rem 0.75rem;
          line-height: 1.65;
          color: var(--white);
          opacity: 1;
        }

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          object-position: center;
          transition: 400ms opacity ease-in-out;
        }
      }
    }
  }

  .hww-process-points {
    padding: 3rem 0;

    .hww-proc-item {
      h4 {
        font-family: var(--font-bold);
        font-size: 2.4rem;
        margin-bottom: 1.25rem;
      }

      h6 {
        font-size: 1.6rem;
        margin-bottom: 0;
        max-width: 90%;
        line-height: 1.65;
      }

      .hww-proc-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        min-width: 3.2rem;
        min-height: 3.2rem;
        max-width: 3.2rem;
        max-height: 3.2rem;
        margin-bottom: 2rem;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  .join-us-wrapper {
    margin-top: 5rem;
  }
}

/* End of How we work */

@media only screen and (min-width: 200px) and (max-width: 499px) {
  .howwework-section .howwework-header h2 {
    font-size: 2.75rem;
  }

  .howwework-section .howwework-header h4 {
    font-size: 1.35rem;
  }

  .howwework-section .howwework-header h6 {
    font-size: 1.2rem;
    max-width: 100%;
  }

  .howwework-section .howwework-method-wrap .container {
    padding: 0;
  }

  .howwework-section .howwework-method-wrap .hwk-circle-wrap {
    max-width: 100%;
  }

  .howwework-section .howwework-method-wrap .hwk-circle-wrap .hwk-circle {
    --dimension: 25rem;
    padding: 4rem;
  }

  .howwework-section .howwework-method-wrap .hwk-circle-wrap .hwk-circle h5 {
    font-size: 0.9rem;
    transform: translateY(80%);
    line-height: 1.3;
  }

  .howwework-section .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row {
    padding: 1.25rem 0;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item {
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item:first-child {
    transform: translateX(-1.5rem);
    flex-direction: column-reverse;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item
    h5 {
    text-align: center;
    font-size: 0.9rem;
    white-space: wrap;
    max-width: 70%;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row.second
    .hwk-item:first-child {
    transform: translateX(0);
    justify-content: flex-end;
    flex-direction: column-reverse;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item:last-child {
    transform: translateX(3rem);
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row.second
    .hwk-item:last-child {
    transform: translateX(0);
  }

  .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item:last-child
    h5 {
    text-align: center;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row.third
    .hwk-item {
    transform: translate(0, -1.5rem);
    flex-direction: column;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row.third
    .hwk-item
    h5 {
    text-align: center;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item
    .hwk-img {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item
    .hwk-img
    svg {
    max-width: 1rem;
  }

  .howwework-section .hww-tech-stack-section {
    padding: 4rem 0 0;
  }

  .howwework-section .hww-tech-stack-section h5 {
    max-width: 100%;
  }

  .howwework-section .hww-tech-stack-section .tech-stack-logos-wrap {
    padding: 1.5rem;
    margin: 3rem 0;
  }

  .howwework-section .hww-process-points {
    padding: 0;
  }

  .howwework-section .hww-process-points .hww-proc-item {
    padding: 2rem 0;
  }

  .howwework-section .join-us-wrapper {
    margin-top: 2rem;
  }

  .howwework-section .join-us-wrapper h2 {
    font-size: 3rem;
  }

  .howwework-section .join-us-wrapper img.join-cta-img {
    max-width: 100%;
    margin: 2rem 0 0;
  }
}

@media only screen and (min-width: 500px) and (max-width: 900px) {
  .howwework-section .howwework-method-wrap .hwk-circle-wrap {
    max-width: 100%;
  }
  .howwework-section .howwework-method-wrap .hwk-circle-wrap .hwk-circle {
    --dimension: 40rem;
  }

  .howwework-section .howwework-method-wrap .hwk-circle-wrap .hwk-circle h5 {
    font-size: 1.1rem;
    transform: translateY(calc(100% - 0.5rem));
    line-height: 1.6;
  }

  .howwework-section .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row {
    padding: 3.5rem 0;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item:first-child {
    transform: translateX(-0.25rem);
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item:last-child {
    transform: translateX(3rem);
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row.third
    .hwk-item:first-child {
    transform: translateX(0);
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item
    .hwk-img {
    width: 2.2rem;
    height: 2.2rem;
    min-width: 2.2rem;
    min-height: 2.2rem;
    max-width: 2.2rem;
    max-height: 2.2rem;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item
    h5 {
    font-size: 1rem;
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item
    .hwk-img
    svg {
    max-width: 1.25rem;
  }

  .howwework-section .howwework-header h2 {
    font-size: 3.5rem;
  }

  .howwework-section .howwework-header h4 {
    font-size: 2rem;
  }

  .howwework-section .howwework-header h6 {
    max-width: 100%;
  }

  .howwework-section .hww-tech-stack-section {
    padding: 4rem 0 0;
  }

  .howwework-section .hww-tech-stack-section h5 {
    max-width: 100%;
  }

  .howwework-section .hww-tech-stack-section .tech-stack-logos-wrap {
    padding: 1.5rem;
    margin: 3rem 0;
  }

  .howwework-section .hww-process-points {
    padding: 0;
  }

  .howwework-section .hww-process-points .hww-proc-item {
    padding: 2rem 0;
  }

  .howwework-section .join-us-wrapper {
    margin-top: 2rem;
  }

  .howwework-section .join-us-wrapper h2 {
    font-size: 3rem;
  }

  .howwework-section .join-us-wrapper img.join-cta-img {
    max-width: 100%;
    margin: 2rem 0 0;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1024px) {
  .howwework-section .howwework-method-wrap .hwk-circle-wrap .hwk-circle {
    --dimension: 47rem;
  }

  .howwework-section .howwework-method-wrap .hwk-circle-wrap .hwk-circle h5 {
    transform: translateY(calc(100% - 1rem));
  }

  .howwework-section .howwework-method-wrap .hwk-circle-wrap {
    max-width: 100%;
  }

  .howwework-section .howwework-method-wrap .hwk-circle-wrap .hwk-circle-row {
    padding: 4rem 0;
  }
  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item:first-child {
    transform: translateX(0.5rem);
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row
    .hwk-item:last-child {
    transform: translateX(4rem);
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row.second
    .hwk-item:first-child {
    transform: translateX(1.5rem);
  }

  .howwework-section
    .howwework-method-wrap
    .hwk-circle-wrap
    .hwk-circle-row.third
    .hwk-item:last-child {
    transform: translate(0, -1rem);
  }
}

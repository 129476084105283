section.about-us {
  padding: 8rem 0 3rem;
}

section.about-us .about-us-header {
  position: relative;
  padding: 0;
  min-height: 55rem;
  height: 100%;
}

.about-us .image-holder {
  display: block;
  margin: 0 auto;
  width: auto;
}

.about-us .image-holder img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.about-us .about-content {
  max-width: 85%;
  transform: translateX(10rem);
}

.about-us p {
  font-size: 1.6rem;
  color: var(--black-21);
  margin: 1rem 0 2rem;
  line-height: 1.65;
}

.about-us p[data-em] {
  font-family: var(--font-medium);
}

.about-content {
  padding: 7rem 0 0;
}

.about-us .about-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 50%;
  margin-right: 0;
}

.about-us img.about-main-img {
  width: 100%;
  max-width: 90%;
  height: 100%;
  margin-right: -0.5rem;
}

.about-us img.about-img {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.about-us .about-down-wrap {
  padding: 1.6rem;
  border: 1px solid var(--light-bd);
  margin: 4rem 0;
}

.about-us .about-down-wrap p {
  margin-left: 3rem;
}

.about-content .about-content-right:not(:last-child) {
  border-bottom: 1px solid var(--light-ee);
}

.about-content-points {
  padding: 0;
  margin: 2rem 0 0;
}

.about-content-points .about-content-right {
  width: 100%;
  padding: 2.4rem;
  border: 1px solid var(--light-bd);
  min-height: 100%;
  transition: 250ms ease;
}

.about-content-points .about-content-right:hover {
  border-color: var(--purple);
}

.about-content-points .about-content-right .ac-img-holder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  max-width: 4rem;
  max-height: 4rem;
  transition: 300ms ease;
}

.about-content-points .about-content-right:hover .ac-img-holder::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: 4rem;
  background-repeat: no-repeat;
  width: 4rem;
  height: 4rem;
}

.about-content-points .about-content-right:hover .ac-img-holder img {
  display: none;
}

.about-content-points
  .about-content-right:hover
  .ac-img-holder.mission::before {
  background: url("../../../images/about/missionSelected.svg");
}

.about-content-points .about-content-right:hover .ac-img-holder.vision::before {
  background: url("../../../images/about/visionSelected.svg");
  height: 3.5rem;
}

.about-content-points .about-content-right:hover .ac-img-holder.values::before {
  background: url("../../../images/about/valuesSelected.svg");
  height: 3.5rem;
}

.about-content-points .about-content-right img {
  width: 100%;
  max-width: 100%;
}

.about-content-points .about-content-right h5 {
  font-family: var(--font-bold);
  font-size: 2.4rem;
  color: var(--black-21);
  margin: 2rem 0;
}

.about-content-points .about-content-right p {
  font-family: var(--font-regular);
  font-size: 1.55rem;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  section.about-us {
    padding: 5rem 0 0;
  }

  section.about-us .about-us-header {
    padding: 50px 0 0;
  }

  .about-us .about-content {
    padding: 4rem 1rem;
    margin: 0;
    max-width: 100%;
    transform: translateX(0);
  }

  .about-us p {
    line-height: 1.65;
    font-size: 1.4rem;
  }

  .about-us img.about-main-img {
    max-width: 100%;
    margin: 0;
  }

  .about-us img.about-img {
    padding: 0 0 2rem 0;
    max-width: 90%;
  }

  .about-us .about-down-wrap p {
    margin-left: 0;
  }

  section.about-us img {
    width: 100%;
    max-width: 100%;
  }

  .about-content-points {
    padding: 0;
    margin: 0;
  }

  .about-content-points .about-content-right {
    width: 100%;
    min-height: calc(100% - 2rem);
    padding: 2rem 1.75rem;
    margin-bottom: 2rem;
  }

  .about-content-points .about-content-right p {
    width: 100%;
  }

  .about-content-points .about-content-right img {
    width: 100%;
    height: 100%;
  }

  .about-content-points .about-content-right .ac-img-holder {
    margin-right: 15px;
  }

  .about-content-points .row > div {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  section.about-us {
    padding-bottom: 0;
  }
  .about-us .about-content {
    width: 100%;
    max-width: 100%;
    transform: translate(0);
    padding: 1rem;
  }
  .about-us img.about-main-img {
    max-width: 100%;
    margin: 0;
    padding: 1rem;
  }
  .about-us img.about-img {
    max-width: 70%;
    padding: 2rem;
  }
  .about-us .container.mobile-container {
    min-width: 100%;
    padding: 1.75rem;
  }
  .about-us .about-down-wrap p {
    margin: 1.25rem 0;
  }
  .about-content-points {
    margin: 0;
  }
  .about-content-points .about-content-right h5 {
    font-size: 1.8rem;
  }
  .about-content-points .about-content-right {
    padding: 1.6rem;
  }
  .about-content-points .about-content-right p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {
  .about-us .about-content {
    margin: 0;
    transform: translateX(5rem);
  }
}

@media only screen and (min-width: 1600px) {
  .about-section {
    margin: 10rem 0;
  }
  section.about-us {
    padding-bottom: 0;
  }

  .about-us .about-img {
    margin: 40px 0;
    padding: 0;
  }

  .about-content-points .about-content-right {
    margin-bottom: 50px;
  }

  .about-us .about-content {
    transform: translateX(17rem);
    max-width: 80%;
  }
}

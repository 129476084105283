section.contact-us {
  padding: 10rem 0 0;
}

.contact-us .contact-us-header {
  margin-bottom: 4rem;
}

.contact-us .contact-us-header h2 {
  font-family: var(--font-bold);
  font-size: 4rem;
  color: var(--black-21);
  margin-bottom: 1.25rem;
}

.contact-us .contact-us-header h3 {
  font-family: var(--font-regular);
  font-size: 2rem;
  color: var(--black-21);
  margin: 0;
}

.contact-us .left-img {
  padding: 1rem;
}

.contact-us .contact-content {
  position: relative;
  z-index: 1;
  padding: 0;
}

.contact-us .contact-content .contact-addr-wrap {
  padding: 0;
  border: 1px solid var(--light-bd);
}

.contact-us .contact-content .contact-addr-wrap .conadr-text {
  padding: 1.6rem;
}

.contact-us .contact-content .contact-addr-wrap .conadr-text h5 {
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: var(--font-medium);
  margin: 0 0 1rem 0;
}

.contact-us .contact-content .contact-addr-wrap .conadr-text h5 img {
  max-width: 1.4rem;
}

.contact-us .contact-content .contact-addr-wrap .conadr-text h6 {
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: var(--font-bold);
  font-size: 2rem;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  margin: 0;
}

.contact-us .contact-content .contact-addr-wrap .conadr-text p.addr-txt {
  font-family: var(--font-medium);
  font-size: 1.6rem;
  margin: 1rem 0;
}

.contact-us .contact-content .contact-addr-wrap .map-wrap {
  margin: 0;
  min-height: 100%;
}

.contact-us .contact-form {
  padding: 0 5rem 0 0;
  margin: 0;
  border-radius: 0.5em;
}

.contact-us .contact-form h5 {
  font-family: var(--font-bold);
  font-size: 2.4rem;
  color: var(--black-21);
}

.contact-us .contact-form h6 {
  font-family: var(--font-regular);
  font-size: 1.4rem;
  margin: 0;
}

.contact-us .contact-form form {
  margin: 3rem 0;
}

.contact-us .contact-form .form-box {
  position: relative;
  padding-bottom: 1.4em;
}

.contact-us .contact-form .form-box input.country-code-input {
  max-width: 7rem;
  padding-inline: 0.5rem;
  text-align: center;
}

.contact-us .contact-form .form-box input.country-code-input:focus {
  outline: none;
  border-color: var(--light-9e);
}

.contact-us .contact-form .form-box img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.contact-us .contact-form .form-box img[data-msg] {
  top: 30px;
  transform: translateY(0px);
}
.contact-us .contact-form .form-box label {
  font-size: 1.4rem;
  color: var(--black-21);
  margin-bottom: 1.2rem;
}

.contact-us .contact-form .form-box input,
.contact-us .contact-form .form-box #msg_textarea {
  position: relative;
  font-family: var(--font-medium);
  font-size: 1.5rem;
  color: var(--black-21);
  border: 1px solid var(--light-9e);
  background: none;
  border-radius: 0px;
  padding: 0.75rem 1.25rem;
}

.contact-us .contact-form .form-box input::placeholder,
.contact-us .contact-form .form-box #msg_textarea::placeholder {
  font-family: var(--font-italic);
  color: var(--light-aa);
}

.contact-us .contact-form .form-box input:focus,
.contact-us .contact-form .form-box #msg_textarea:focus {
  border-color: var(--black-21);
}

.contact-us .contact-form .form-box input:focus label {
  color: var(--purple);
}

.contact-us .sending-msg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px 20px;
  margin: 20px 0 0;
  overflow: hidden;
  border-radius: 3px;
  border: 2px solid rgba(0, 128, 128, 0.1);
}

.contact-us .sending-msg h4 {
  font-family: var(--font-semibold);
  font-size: 16px;
  color: teal;
  margin: 0;
}

.contact-us .sending-msg img {
  width: 100%;
  max-width: 30px;
}

.contact-us .contact-info-wrap {
  margin: 0;
  padding: 3rem 0 0;
  margin: 3rem 0 0;
  border-top: 1px solid var(--light-e0);
}

.contact-us .contact-info-wrap h4 {
  position: relative;
  font-family: var(--heading-regular);
  font-size: 32px;
  text-align: center;
}

.contact-us .contact-info-wrap h4::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 56px;
  height: 2px;
  background: var(--purple);
}

.contact-us .contact-info-wrap h4 span {
  font-family: var(--heading-medium);
  color: var(--purple);
}

.contact-us .contact-info {
  background: none;
}

.contact-us img.contact-img {
  width: 100%;
  max-width: 664px;
  margin: 5rem 0 0;
  padding: 0;
}

.contact-us .con-item {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 2rem 0 3.5rem;
}

.contact-us .con-item img {
  max-width: 45px;
  margin-bottom: 15px;
}

.contact-us .con-det {
  color: var(--black);
  padding: 0;
}

.contact-us .con-det h5 {
  font-family: var(--font-medium);
  font-size: 1.4rem;
  color: var(--black-42);
  margin-bottom: 7px;
}

.contact-us .con-det h6 {
  font-family: var(--font-bold);
  font-size: 2rem;
  color: var(--black);
  margin: 0;
}

.contact-us .con-det h6[data-blue] {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: var(--purple-1);
  cursor: pointer;
  margin: 1em 0 0;
}

.contact-us .con-det h6 a {
  font-family: inherit;
  color: var(--black-21);
}

@media only screen and (max-width: 767px) {
  section.contact-us {
    padding: 7rem 0 1rem;
  }

  .contact-us .contact-content {
    padding: 2rem 0 1rem;
  }

  .contact-us .contact-form {
    margin: 3rem 0;
    padding: 0;
  }

  .contact-us p.tag {
    font-size: 20px;
    text-align: center;
  }

  .contact-us .contact-us-header {
    margin: 0;
  }

  .contact-us .contact-us-header h2 {
    font-size: 2.6rem;
    text-align: left;
  }

  .contact-us .contact-us-header h3 {
    font-size: 1.6rem;
    text-align: left;
    line-height: 1.5;
  }

  .contact-us .contact-us-header .container {
    padding: 0;
  }

  .contact-us img.contact-img {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 30px auto 0;
    padding: 0;
  }
  .contact-us .contact-info-wrap {
    padding: 1rem 0;
  }
  .contact-us .contact-info {
    padding: 0;
    margin: 0;
  }

  .contact-us .contact-info-wrap h4 {
    margin-bottom: 30px;
  }

  .contact-us .contact-info h5 {
    font-size: 1.25rem;
    line-height: 1.8;
    margin-bottom: 0.25em;
  }

  .contact-us .con-item {
    padding: 2rem 0;
    border: none;
    margin: 0;
    border-bottom: 1px solid var(--light-ee);
  }

  .contact-us .con-item[data-line]::after {
    background: none;
  }

  .contact-us .contact-info h6 {
    font-size: 1.4rem;
  }

  .contact-us .con-det h6 a {
    font-size: 1.75rem;
  }

  .contact-us .map-wrap {
    margin-top: 1.25em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .contact-us .contact-us-header h2 {
    font-size: 3rem;
  }
  .contact-us .contact-us-header h3 {
    font-size: 1.6rem;
  }
  .contact-us .contact-form {
    padding: 0;
  }
  .contact-us .con-item {
    margin: 1.5rem 0;
  }
}

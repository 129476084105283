/* Portfolio Styles */

.company-portfolio-wrap {
  padding: 7.8rem 0 0;
}

.company-portfolio-wrap .comp-portfolio-header {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--black-21);
  isolation: isolate;
}

.company-portfolio-wrap .comp-portfolio-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.35);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.company-portfolio-wrap .comp-portfolio-header .comp-video-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 7.8rem);
  overflow: hidden;
}

.company-portfolio-wrap .comp-portfolio-header .comp-video-bg #portVideo {
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center center;
}

.company-portfolio-wrap .comp-portfolio-header .comp-port-content {
  width: 100%;
  max-width: 70%;
  margin: 2rem auto 0;
  text-align: center;
  color: var(--white);
}

.company-portfolio-wrap .comp-portfolio-header .comp-port-content h2 {
  font-family: var(--font-bold);
  font-size: 8rem;
}

.company-portfolio-wrap .comp-portfolio-header .comp-port-content h3 {
  font-family: var(--font-bold);
  font-size: 4rem;
}

.company-portfolio-wrap .comp-portfolio-header .comp-port-content h6 {
  font-size: 1.6rem;
  color: var(--white);
  margin: 2rem auto 0;
  max-width: 70%;
}

/* Projects Section */

.company-portfolio-wrap .projects-total-wrap {
  padding: 5rem 0 0;
}

.company-portfolio-wrap .projects-total-wrap .project-items-wrap {
  padding: 5rem 0;
}

.company-portfolio-wrap .projects-total-wrap .project-item {
  padding: 0;
}

.company-portfolio-wrap .projects-total-wrap .project-item.center {
  text-align: center;
}

.company-portfolio-wrap .projects-total-wrap .project-items-wrap.cam {
  background: url("../../../images/projects/cam_bg.png");
  background-size: cover;
  background-position: center center;
  padding: 7rem 0;
}

.company-portfolio-wrap
  .projects-total-wrap
  .project-items-wrap.refurnish
  .project-item {
  background: url("../../../images/projects/dr_bg.png");
  background-size: cover;
}

.company-portfolio-wrap .projects-total-wrap .project-item h4 {
  font-family: var(--font-bold);
  font-size: 4rem;
  color: var(--black-21);
  margin-bottom: 1.5rem;
}

.company-portfolio-wrap .projects-total-wrap .project-item span.pr-tag {
  font-family: var(--font-bold);
  font-size: 1.6rem;
  color: var(--white);
  padding: 0.5rem 1.25rem;
}

.company-portfolio-wrap .projects-total-wrap .project-item span.pr-tag.orange {
  background: #f7941e;
}

.company-portfolio-wrap .projects-total-wrap .project-item span.pr-tag.blue {
  background: #005eb8;
}

.company-portfolio-wrap .projects-total-wrap .project-item span.pr-tag.black {
  background: var(--black);
}

.company-portfolio-wrap .projects-total-wrap .project-item span.pr-tag.green {
  background: #019345;
}

.company-portfolio-wrap .projects-total-wrap .project-item span.pr-tag.yellow {
  background: #eecc35;
  color: var(--black);
}

.company-portfolio-wrap .projects-total-wrap .project-item h6 {
  font-family: var(--font-bold);
  font-size: 1.6rem;
  color: #005bff;
}

.company-portfolio-wrap .projects-total-wrap .project-item p {
  font-size: 1.6rem;
  color: var(--black-21);
  margin: 1rem 0 1.25rem;
  line-height: 1.65;
}

.company-portfolio-wrap .projects-total-wrap .project-item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.company-portfolio-wrap
  .projects-total-wrap
  .project-items-wrap.cam
  .project-item-image {
  margin: 5rem 0;
}

.company-portfolio-wrap
  .projects-total-wrap
  .project-items-wrap.cam
  .project-item-image
  img {
  max-width: 60%;
}

.company-portfolio-wrap .projects-total-wrap .project-item-image img {
  width: 100%;
  max-width: 90%;
}

/* End of Projects Section */

.clients-partner-wrap {
  text-align: center;
  margin: 7rem 0;
}

.clients-partner-wrap h4 {
  font-family: var(--font-black);
  font-size: 3.2rem;
  color: var(--black-21);
  margin-bottom: 1.25rem;
}

.clients-partner-wrap h2 {
  font-family: var(--font-black);
  font-size: 3.2rem;
  color: var(--light-bd);
  text-transform: uppercase;
}

.clients-partner-wrap .clients-partner-logo-wrap {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
}

.clients-partner-wrap .clients-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clients-partner-wrap .clients-item img {
  width: 60%;
  height: 15rem;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}

/* Interested Project */

.interested-project-cta {
  margin-block-start: 7rem;
  margin-block-end: 9rem;
  padding: 3.2rem;
  border: 1px solid var(--black-21);
}

.interested-project-cta h2 {
  font-family: var(--font-black);
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 1.25rem;
}

.interested-project-cta h5 {
  font-size: 1.6rem;
  text-align: center;
}

.interested-project-cta .ip-prof-wrap {
  padding: 4rem 3rem 0 3rem;
}

.interested-project-cta .ip-prof-wrap h4 {
  font-family: var(--font-bold);
  font-size: 3.2rem;
  color: var(--purple);
}

.interested-project-cta .ip-prof-wrap h6 {
  font-size: 1.6rem;
}

.interested-project-cta .ip-prof-wrap ul {
  margin-top: 1.5rem;
  border-top: 1px solid var(--light-ee);
}

.interested-project-cta .ip-prof-wrap ul li {
  font-family: var(--font-medium);
  font-size: 1.45rem;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 1.25rem 0;
}

.interested-project-cta .ip-prof-wrap p.port_foot_note {
  font-family: var(--font-italic);
  font-size: 1.45rem;
  width: 100%;
  background: var(--light-fa);
  padding: 1.4rem;
  margin: 2.5rem 0 0;
}

/* End of Portfolio Styles */

@media only screen and (max-width: 767px) {
  .company-portfolio-wrap {
    padding-top: 7rem;
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-video-bg {
    min-height: 100%;
  }
  .company-portfolio-wrap .comp-portfolio-header {
    min-height: calc(80vh - 7.8rem);
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-video-bg #portVideo {
    max-height: calc(80vh - 7.8rem);
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-port-content {
    width: 100%;
    max-width: 100%;
    padding: 4rem 0 3rem;
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-port-content h2 {
    font-size: 3rem;
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-port-content h3 {
    font-size: 1.8rem;
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-port-content h6 {
    font-size: 1.2rem;
    max-width: 90%;
    line-height: 1.5;
  }
  .company-portfolio-wrap .projects-total-wrap {
    padding: 2.5rem 0 0;
  }
  .company-portfolio-wrap .projects-total-wrap .project-items-wrap.cam {
    padding: 3rem 0;
  }
  .company-portfolio-wrap .projects-total-wrap .project-items-wrap {
    padding: 1.5rem 0;
  }
  .company-portfolio-wrap .projects-total-wrap .project-item h4 {
    font-size: 2.5rem;
  }
  .company-portfolio-wrap .projects-total-wrap .project-item span.pr-tag {
    font-size: 1.25rem;
  }
  .company-portfolio-wrap .projects-total-wrap .project-item p {
    font-size: 1.4rem;
    margin: 2rem 0;
  }
  .company-portfolio-wrap .projects-total-wrap .project-item-image img {
    max-width: 100%;
    margin: 3rem 0;
  }
  .company-portfolio-wrap
    .projects-total-wrap
    .project-items-wrap.cam
    .project-item-image
    img {
    max-width: 100%;
  }
  .clients-partner-wrap {
    margin: 2rem 0 0;
  }
  .company-portfolio-wrap
    .projects-total-wrap
    .project-items-wrap.refurnish
    .project-item {
    background: none;
  }
  .company-portfolio-wrap
    .projects-total-wrap
    .project-items-wrap.refurnish
    .project-item
    .row {
    flex-direction: column-reverse;
  }
  .clients-partner-wrap h2 {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .company-portfolio-wrap .comp-portfolio-header {
    min-height: 100%;
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-video-bg {
    min-height: 100%;
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-port-content {
    max-width: 90%;
    margin: 6rem 0 3rem;
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-port-content h2 {
    font-size: 4rem;
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-port-content h3 {
    font-size: 2.5rem;
  }
  .company-portfolio-wrap .comp-portfolio-header .comp-port-content h6 {
    font-size: 1.4rem;
    max-width: 100%;
    line-height: 1.6;
  }
  .company-portfolio-wrap .projects-total-wrap {
    padding: 3rem 0 0;
  }
  .company-portfolio-wrap .projects-total-wrap .project-items-wrap {
    padding: 3rem 0;
  }
  .company-portfolio-wrap .projects-total-wrap .project-item span.pr-tag {
    font-size: 1.4rem;
  }
  .company-portfolio-wrap .projects-total-wrap .project-item h4 {
    font-size: 3rem;
  }
  .company-portfolio-wrap .projects-total-wrap .project-item-image {
    margin: 3rem 0;
  }

  .company-portfolio-wrap
    .projects-total-wrap
    .project-item
    .row.proj-refurnish {
    flex-direction: column-reverse;
  }

  .company-portfolio-wrap
    .projects-total-wrap
    .project-items-wrap.cam
    .project-item-image
    img {
    max-width: 90%;
  }
  .company-portfolio-wrap
    .projects-total-wrap
    .project-items-wrap.refurnish
    .project-item {
    background: none;
  }
}

section.legal-section {
  padding: 7em 0 3em;
}

section.legal-section h2 {
  position: relative;
  padding: 0;
  text-align: center;
  margin: 3rem 0 0;
  font-family: var(--font-bold);
  font-size: 4rem;
  color: var(--black-21);
}

section.legal-section .legal-wrap {
  margin: 30px 0 0;
}

section.legal-section p {
  font-family: var(--font-regular);
  font-size: 1.6rem;
  color: var(--black-21);
  line-height: 1.75;
  margin: 1.75rem 0;
}

section.legal-section span {
  font-size: 1.6rem;
}

section.legal-section h5 {
  font-family: var(--font-bold);
  font-size: 2.4rem;
  margin: 4rem 0 1.5rem;
}

section.legal-section ul {
  list-style-type: none;
  padding: 0.5em 0 0 1.5em;
  margin: 0;
}

section.legal-section ul li {
  padding: 0.5rem 0;
  font-size: 1.6rem;
}

section.legal-section ul li span {
  font-family: var(--font-medium);
}

section.legal-section ul li::marker {
  content: "- ";
}

@media only screen and (max-width: 767px) {
  section.legal-section {
    padding: 5.5em 1em 2em;
  }

  .legal-wrap img {
    width: 100%;
    margin: 20px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .legal-wrap img {
    margin: 30px 0 40px 0;
  }
}

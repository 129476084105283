/* Font Imports */

/* Satoshi */

@font-face {
  font-family: "Satoshi-Light";
  src: url("../../fonts/Satoshi/Satoshi-Light.otf");
}

@font-face {
  font-family: "Satoshi-Italic";
  src: url("../../fonts/Satoshi/Satoshi-Italic.otf");
}

@font-face {
  font-family: "Satoshi-Regular";
  src: url("../../fonts/Satoshi/Satoshi-Regular.otf");
}

@font-face {
  font-family: "Satoshi-Medium";
  src: url("../../fonts/Satoshi/Satoshi-Medium.otf");
}

@font-face {
  font-family: "Satoshi-MediumItalic";
  src: url("../../fonts/Satoshi/Satoshi-MediumItalic.otf");
}

@font-face {
  font-family: "Satoshi-Bold";
  src: url("../../fonts/Satoshi/Satoshi-Bold.otf");
}

@font-face {
  font-family: "Satoshi-Black";
  src: url("../../fonts/Satoshi/Satoshi-Black.otf");
}

/* Global Variables */
:root {
  /* Satoshi Fonts */
  --font-light: "Satoshi-Light";
  --font-italic: "Satoshi-Italic";
  --font-regular: "Satoshi-Regular";
  --font-medium: "Satoshi-Medium";
  --font-medium-italic: "Satoshi-MediumItalic";
  --font-bold: "Satoshi-Bold";
  --font-black: "Satoshi-Black";
  /* Colors */
  --purple: #5e00cc;
  --purple-light: #ece1ff;
  --purple-light-1: rgba(236, 225, 255, 0.35);
  --purple-light: #8d6eb1;
  --red-light: #f38a8b;
  --black: #000;
  --black-21: #212121;
  --black-24: #242424;
  --black-42: #424242;
  --dark-22: #222;
  --dark-33: #333;
  --dark-55: #555;
  --dark-77: #777;
  --dark-99: #999;
  --white: #fff;
  --light-70: #707070;
  --light-75: #757575;
  --light-61: #616161;
  --light-9e: #9e9e9e;
  --light-ee: #eee;
  --light-e0: #e0e0e0;
  --light-fa: #fafafa;
  --light-fd: #fdfdfd;
  --light-aa: #aaa;
  --light-bd: #bdbdbd;
  --light-d6: #d6d6d6;
  --light-f9: #f9f9f9;
  --light-f2f3: #f2f3f4;
  --light-f3: #f3f3f3;
  --light-fc: #fcfcfc;
  --highlight: #fd6584;
}

/* Resets */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Satoshi-Regular", sans-serif;
}

*::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: var(--light-aa);
  border-radius: 1em;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark-77);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1,
h2,
h3 {
  font-family: var(--font-bold);
  line-height: 1.4;
}

h4,
h5 {
  font-family: var(--font-medium);
}

h6 {
  font-family: var(--font-regular);
  font-size: 1.09rem;
  line-height: 1.5;
  color: var(--black-21);
}

p {
  font-family: inherit;
  font-size: 1.02rem;
  line-height: 1.5;
  color: var(--black-21);
}

button:focus,
button:focus-within {
  box-shadow: none !important;
  outline: none;
}

.highlight {
  font-family: var(--font-bold) !important;
  color: var(--black);
  padding: 0.15em 0;
  line-height: 1;
}

.form-control:focus,
.form-control:focus-within {
  box-shadow: none;
  border-color: var(--purple);
}

textarea {
  resize: none;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #eee;
  margin: 1.5rem 0;
}

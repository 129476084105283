@import url("../App.css");

/***** LANDING PAGE  *****/

/* Main Section */

.main-container {
  position: relative;
  z-index: 1;
  isolation: isolate;
}

.main-container .main-section {
  position: relative;
  margin-top: 0;
  margin-bottom: 4rem;
  padding-bottom: 3rem;
  isolation: isolate;
}

.main-container .main-section .video-bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  max-height: 100dvh;
  overflow: hidden;
}

.main-container .main-section .video-bg #video-main {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;
  object-fit: cover;
}

.main-section .content {
  position: relative;
  padding: 12rem 0 3rem;
  z-index: 2;
}

.content-text {
  width: 100%;
}

.content .content-text h1,
.content .content-text h2 {
  font-family: var(--font-bold);
  font-size: 8rem;
  text-align: center;
  color: var(--black-21);
  margin: 0;
  line-height: 1.25;
}

.content .content-text h1 {
  font-family: var(--font-bold);
}

.content .content-text h2 {
  font-family: var(--font-regular);
}

.content .content-text h1 span {
  position: relative;
  font-family: var(--font-bold);
  padding: 0 1.5rem 0 1rem;
  line-height: 1.55;
}

.content .content-text h1 span::before {
  content: "";
  position: absolute;
  bottom: -7px;
  right: -7px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--purple);
}

.content h1 span[data-block] {
  color: var(--white);
  background: var(--purple);
}

.content h5.subtitle {
  font-family: var(--font-medium);
  font-size: 2.4rem;
  margin: 0 auto;
  padding: 30px 0 0;
  line-height: 1.75;
  width: 70%;
  text-align: center;
  color: var(--dark-33);
}

.content h5.subtitle span {
  font-family: var(--font-medium-italic);
}

.content h5.subtitle span[data-blue] {
  color: #005bff;
}

.content h5.subtitle span[data-red] {
  color: #ff3838;
}

.content h5.subtitle span[data-purple] {
  color: #7317ff;
}

.content h5.subtitle span[data-green] {
  color: #31a14b;
}

.content h5.subtitle span[data-teal] {
  color: #22847a;
}

.content button[data-reach] {
  position: relative;
  cursor: pointer;
  font-family: var(--font-bold);
  font-size: 16px;
  background: linear-gradient(
    to right,
    #f38a8b,
    #a117e8 30%,
    #dd03e9 67%,
    #6d37ef
  );
  background-clip: text;
  color: var(--purple);
  border: 1px solid var(--purple);
  box-shadow: 6px 6px 10px 0 rgba(228, 131, 142, 0.2);
  z-index: 2;
}

.content button[data-reach]:hover {
  background: var(--black);
  color: var(--white);
  border: 1px solid var(--black);
}

.content button[data-reach]::before {
  content: "";
  padding: 0;
}

.content button img {
  width: 14px;
}

.content .content-text .home-reach-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5rem 0 1rem;
}

/* About us */

.about-section {
  margin: 5rem 0;
  padding: 0;
}

.about-section .sec-header h4 {
  font-family: var(--font-bold);
  font-size: 2.4rem;
  margin-bottom: 1.25rem;
}

.about-section .sec-header h6 {
  font-size: 1.6rem;
  max-width: 50%;
}

.about-section p {
  font-size: 1.15em;
  line-height: 1.9;
}

.about-section .services {
  padding: 4em 0 2em;
}

.about-section .services .service-item {
  margin: 0 auto;
  width: 80%;
}

.about-section .services .service-item:not(:last-child) {
  padding: 0 0 10em 0;
}

.about-section .services .row {
  align-items: center;
}

.about-section .services img {
  width: 27em;
  max-width: 85%;
  padding-right: 2em;
}

.about-section .services h3 {
  font-size: 1.5em;
}

.about-section .services p {
  color: var(--dark-55);
}

.about-section .tag-group li {
  padding-bottom: 1em;
}

.sec-header {
  position: relative;
  padding: 0;
}

.sec-header .view-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  background-color: var(--black);
  padding: 1em 1.75em;
  margin: 3em 0 0;
  line-height: 1;
  text-transform: uppercase;
  transition: 0.2s ease-in-out all;
}

.sec-header .view-btn:hover {
  background-color: var(--dark-332);
}

.sec-header .view-btn:hover img {
  transform: translateX(5px);
}

.sec-header .view-btn span {
  font-family: var(--font-bold);
  color: var(--white);
  font-size: 1.09em;
  margin-top: 0.2em;
  letter-spacing: 0.6px;
}

.sec-header .view-btn img {
  transition: 0.2s ease-in-out all;
  width: 16px;
}

.box-group {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 1em;
}

.box-group .box-item {
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 10%);
  padding: 2em;
  border-radius: 15px;
}

.box-group .box-item[data-down] {
  margin-top: 2.5em;
}

.box-group .box-item img {
  width: 80px;
  height: 80px;
}

.box-group .box-item h6 {
  font-family: var(--font-bold);
  margin: 1em 0 0.5em;
}

.box-group .box-item p {
  font-size: 1.15em;
  line-height: 1.65;
  margin: 0;
}

.home-about-service-section {
  margin: 5rem 0;
}

.home-about-service-section .home-about-item {
  position: relative;
  padding: 1.6rem;
  border: 1px solid var(--light-70);
  min-height: 26.4rem;
  cursor: pointer;
  transition: 250ms ease;
}

.home-about-service-section .home-about-item .habt-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  max-width: 3rem;
  max-height: 3rem;
}

.home-about-service-section .home-about-item:hover .habt-img img {
  display: none;
  max-width: 100%;
}

.home-about-service-section .home-about-item:hover .habt-img::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  width: 2.4rem;
  height: 2.4rem;
}

.home-about-service-section .home-about-item:hover .habt-img.design::before {
  background: url("../../../images/design_dev_selected.svg");
}

.home-about-service-section .home-about-item:hover .habt-img.consult::before {
  background: url("../../../images/consult_plan_selected.svg");
  width: 3rem;
}

.home-about-service-section .home-about-item:hover .habt-img.deploy::before {
  background: url("../../../images/deploy_manage_selected.svg");
}

.home-about-service-section .home-about-item:hover .habt-img.market::before {
  background: url("../../../images/market_reach_selected.svg");
}

.home-about-service-section .home-about-item:hover {
  border-color: var(--purple);
  box-shadow: 24px 24px 32px 0 rgba(0, 0, 0, 0.04);
}

.home-about-service-section .home-about-item h4 {
  font-family: var(--font-regular);
  font-size: 2.4rem;
  color: var(--black-42);
  margin: 2rem 0;
  max-width: 60%;
}

.home-about-service-section .home-about-item:hover h4 {
  font-family: var(--font-bold);
}

.home-about-service-section .home-about-item h6 {
  font-family: var(--font-regular);
  font-size: 1.6rem;
  color: var(--black-21);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 300ms ease;
}

.home-about-service-section .home-about-item:hover h6 {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.home-about-service-section .home-about-item .habt-arrow-img {
  position: absolute;
  right: 1.6rem;
  bottom: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;
  max-width: 2.4rem;
  max-height: 2.4rem;
  background: var(--black-21);
  transition: 300ms ease;
}

.home-about-service-section .home-about-item:hover .habt-arrow-img {
  width: 4.8rem;
  height: 4.8rem;
  min-width: 4.8rem;
  min-height: 4.8rem;
  max-width: 4.8rem;
  max-height: 4.8rem;
  transform: translateY(3.85rem);
  background: var(--purple);
}

.home-about-service-section .home-about-item .habt-arrow-img svg {
  transition: 300ms ease;
}

.home-about-service-section .home-about-item:hover .habt-arrow-img svg {
  width: 2.4rem;
  height: 2.05rem;
}

.home-service-wrap {
  text-align: center;
  padding: 20px 10px;
  min-height: 500px;
  max-height: 500px;
}

.home-service-wrap .hs-text {
  padding: 30px 0 0;
  margin: 0;
}

.home-service-wrap h4 {
  font-family: var(--font-semibold);
  font-size: 24px;
  color: var(--purple);
  margin-bottom: 20px;
}

.home-service-wrap p {
  font-family: inherit;
  font-size: 16px;
  color: var(--black);
  line-height: 24px;
}

.home-service-wrap .hms-img img {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/*********** END OF ABOUT SECTION  ************/

/* Project CTA */

.project-cta {
  position: relative;
  z-index: 1;
  isolation: isolate;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #000 0%, rgba(106, 106, 106, 0) 100%),
    url("../../../images/project_cta_bg.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 0;
  margin: 0;
}

.project-cta .project-stat-content {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 48%);
  padding: 4rem 0;
}

.project-cta .project-stat-content h2 {
  font-family: var(--font-bold);
  font-size: 4rem;
  color: var(--white);
  line-height: 1.2;
  position: relative;
  margin: 0 0 3rem 0;
  z-index: 1;
}

.project-cta .project-stat-content h5 {
  display: inline-block;
  position: relative;
  font-family: var(--font-regular);
  font-size: 3rem;
  color: var(--white);
}

.project-cta .project-stat-content img {
  max-width: 100%;
}

.project-cta .project-stat-text-content {
  position: relative;
}

.project-cta .proj-stat-text {
  padding: 3rem 0 0;
}

.project-cta .proj-stat-text a {
  display: inline-block;
}

.project-cta .reach-us {
  position: relative;
  font-family: var(--font-bold);
  font-size: 16px;
  background: var(--white);
  color: var(--purple);
  padding: 7px 25px;
  margin: 20px 0 0;
  border: 1px solid currentColor;
  box-shadow: 0 6px 16px 0 rgba(82, 54, 149, 0.16);
  cursor: pointer;
  z-index: 1;
  min-width: 128px;
}

.project-cta .reach-us:hover {
  color: var(--white);
  border: 1px solid transparent;
}

.project-cta .reach-us::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 4px;
  height: 100%;
  background: var(--purple);
  transition: 0.2s ease-in-out width;
}

.project-cta .reach-us::after {
  content: "";
  position: absolute;
  top: 0;
  right: -4px;
  z-index: -1;
  width: 4px;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background: var(--purple-light);
  transition: 0.2s ease-in-out all;
}

.project-cta .reach-us:hover::before {
  width: calc(100% - 4px);
}

.project-cta .reach-us:hover::after {
  right: 0;
  opacity: 1;
  visibility: visible;
}

/* Project Section */

.projects-section {
  position: relative;
  margin: 4rem 0 0;
  padding: 2rem 0 0;
}

.projects-section h1 {
  font-family: var(--font-light);
  font-size: 4rem;
  text-align: center;
  margin: 0;
}

.projects-section .olw-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7rem 0 3rem;
}

.projects-section .olw-wrap .olw-left {
  text-align: left;
}

.projects-section .olw-wrap .olw-left h4 {
  font-family: var(--font-bold);
  font-size: 2.4rem;
  color: var(--black-21);
  margin-bottom: 1rem;
}

.projects-section .olw-wrap .olw-left h6 {
  font-size: 1.6rem;
  max-width: 75%;
  line-height: 1.5;
}

.projects-section h2::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 56px;
  height: 2px;
  background: var(--purple);
}

.projects-section h5.subtitle {
  font-family: var(--font-regular);
  font-size: 1.6rem;
  padding: 40px 0;
  line-height: 28px;
  width: 90%;
  margin: 0 auto;
  color: var(--black);
}

section.project-area-wrap#sticky-div {
  padding: 0;
}

.project-area-wrap .project-items-wrap {
  height: 100%;
  padding: 0;
}

.project-area-wrap .project-items-wrap .project-item {
  padding: 7rem 0;
  background: var(--white);
}

.project-area-wrap .project-items-wrap .project-item .project-item-content {
  position: relative;
  min-height: 100vh;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-content:last-child {
  min-height: 50vh;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-content:last-child
  .project-intersection-line {
  margin-bottom: 0;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-content
  .project-intersection-line {
  position: relative;
  top: -100%;
  left: 0;
  width: 100%;
  height: 2rem;
  background: none;
  margin-bottom: 20%;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-content
  span {
  font-family: var(--font-bold);
  font-size: 1.6rem;
  color: var(--light-bd);
}

.project-area-wrap .project-items-wrap .project-item .project-item-content h4 {
  font-family: var(--font-bold);
  font-size: 3.2rem;
  color: var(--black-21);
  margin-bottom: 2rem;
}

.project-area-wrap .project-items-wrap .project-item .project-item-content h6 {
  font-family: var(--font-bold);
  font-size: 1.6rem;
  color: var(--color);
  margin-bottom: 1rem;
}

.project-area-wrap .project-items-wrap .project-item .project-item-content p {
  font-size: 1.6rem;
  max-width: 70%;
}

.project-area-wrap .project-items-wrap .project-item .project-item-image {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  top: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms ease;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-image
  .project-image-in {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100%;
  max-width: 80%;
  height: 100%;
  margin-top: 1rem;
  overflow: hidden;
  transition: 400ms ease-in;
  transition-delay: 300ms;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-image
  .project-image-in.edr-in {
  background: url("../../../images/projects/Edr_home.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-image
  .project-image-in.cam-in {
  background: url("../../../images/projects/Cam_home.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-image
  .project-image-in.king-in {
  background: url("../../../images/projects/Kg_home.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-image
  .project-image-in.south-in {
  background: url("../../../images/projects/Sp_home.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-image
  .project-image-in.refurn-in {
  background: url("../../../images/projects/Dr_home.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-image
  .project-image-in.refurn-in-last {
  transition-delay: 0s;
  transition: 0s;
}

.project-area-wrap .project-items-wrap .project-item .project-item-image img {
  width: 100%;
  max-width: 100%;
  transition: 350ms ease;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-content
  .animated-btn-wrap {
  visibility: hidden;
  opacity: 0;
  transform: translate(-8rem);
  pointer-events: none;
  margin-top: 5rem;
  transition: 550ms ease-in-out all;
  transition-delay: 750ms;
}

.project-area-wrap
  .project-items-wrap
  .project-item
  .project-item-content
  .animated-btn-wrap.button-animated-in {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
  pointer-events: auto;
}

/* End of Project Section */

/* Newsletter Section */

.newsletter-section {
  padding: 5rem 0;
}

.newsletter-section .news-image img {
  width: 100%;
  max-width: 90%;
}

.newsletter-section .news-content-wrap {
  padding: 3rem 0 0;
}

.newsletter-section .news-text-wrap h4 {
  font-family: var(--font-bold);
  font-size: 4rem;
  color: var(--purple);
  margin-bottom: 1rem;
}

.newsletter-section .news-text-wrap h5 {
  font-family: var(--font-regular);
  font-size: 2.4rem;
  color: var(--black-21);
}

.newsletter-section .news-text-wrap h6,
.newsletter-section .news-subscribe-wrap h6 {
  font-family: var(--font-bold);
  font-size: 1.6rem;
  color: var(--black-21);
  margin: 4rem 0 0.5rem;
}

.newsletter-section .news-subscribe-wrap h6 {
  margin: 0;
}

.newsletter-section .news-text-wrap p,
.newsletter-section .news-subscribe-wrap p {
  font-size: 1.45rem;
  color: var(--black-21);
  max-width: 75%;
}

.newsletter-section .news-subscribe-wrap {
  border: 1px solid var(--light-75);
  padding: 1.6rem;
  max-width: 80%;
  margin: 4rem 0;
}

.newsletter-section .news-subscribe-wrap .nwsub-input-wrap {
  display: flex;
  align-items: center;
  margin: 2rem 0 0;
}

.newsletter-section .news-subscribe-wrap .nwsub-input-wrap input {
  font-family: var(--font-medium);
  font-size: 1.6rem;
  width: 100%;
  min-height: 4.8rem;
  border: 1px solid var(--light-9e);
  border-right: none;
  outline: none;
  padding-left: 1.6rem;
}

.newsletter-section .news-subscribe-wrap .nwsub-input-wrap input:focus {
  border-color: var(--black-21);
}

.newsletter-section .news-subscribe-wrap .nwsub-input-wrap button {
  white-space: nowrap;
  min-height: 4.8rem;
  min-width: 19rem;
}

.newsletter-section .news-subscribe-wrap .nwsub-input-wrap button:disabled {
  position: relative;
  font-family: var(--font-medium);
  color: var(--white);
  min-width: 19rem;
}

.newsletter-section
  .news-subscribe-wrap
  .nwsub-input-wrap
  button:disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background: var(--purple);
  color: var(--white);
  animation: loading 1300ms ease-out forwards;
}

@keyframes loading {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.newsletter-section
  .news-subscribe-wrap
  .nwsub-input-wrap
  button:disabled
  .common-btn-img {
  display: none;
}

.newsletter-section
  .news-subscribe-wrap
  .nwsub-input-wrap
  button:disabled:hover {
  background: none;
}

/* End of Newsletter Section */

/* Track Record */

.our-stats {
  margin: 3rem 0 0;
  background: var(--white);
}

.ourstats-container {
  position: relative;
  padding: 2rem 0 5rem;
}
.ourstats-container h2 {
  position: relative;
  font-family: var(--font-regular);
  font-size: 8rem;
  color: var(--black-21);
}

.ourstats-container h2 span {
  font-family: var(--font-medium);
}

/* .ourstats-container h2::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 56px;
  height: 2px;
  background: var(--purple);
} */

.ourstats-container p {
  font-family: inherit;
  font-size: 1.6rem;
  margin: 1rem 0;
  color: var(--black-21);
  max-width: 45%;
}

.ourstats-container img {
  width: 15rem;
  max-width: 100%;
}

.stats-box-wrap {
  padding: 0;
  margin: 5rem 0 3rem;
}

.stat-box {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 3.2rem;
  margin: 0 0 1.6rem 0;
  border: 1px solid var(--black-21);
  transition: 250ms ease;
}

.stat-box:hover {
  border-color: var(--purple);
}

.stat-box.proj-done {
  position: relative;
  padding-bottom: 0;
  min-height: calc(100% - 1.6rem);
}

.stat-box.proj-done img.stat-big-img {
  margin-bottom: 0;
}

.stat-box img.stat-big-img.projects-done {
  width: 80%;
  margin-bottom: 0;
  transform: translate(-4.8rem, 0);
  transition: 300ms ease;
}

.stat-box.proj-done img.stat-big-img.projects-done.selected,
.stat-box.hap-cl img.stat-big-img.happy-clients.selected {
  display: none;
}

.stat-box.proj-done:hover img.stat-big-img.projects-done,
.stat-box.hap-cl:hover img.stat-big-img.happy-clients {
  display: none;
}

.stat-box.proj-done:hover img.stat-big-img.projects-done.selected,
.stat-box.hap-cl:hover img.stat-big-img.happy-clients.selected {
  display: block;
}

.stat-box h4 {
  font-size: 32px;
  font-family: var(--font-bold);
  color: var(--black);
  margin-bottom: 7px;
}

.stat-box h5 {
  font-family: var(--font-medium);
  font-size: 2.4rem;
  margin: 1.5rem 0;
  color: var(--black-42);
}

.stat-box h6 {
  font-size: 1.6rem;
  margin: 0;
  color: var(--light-61);
  max-width: 80%;
  line-height: 1.5;
}

.stat-box img {
  width: auto;
  margin-bottom: 2.4rem;
}

#projectsBtn {
  font-family: var(--font-bold);
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  background: none;
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 0px;
  padding: 0.8em 1.25em;
  margin: 2em 0 0;
  line-height: 1;
  text-transform: uppercase;
  transition: 0.2s ease-in-out all;
}

#projectsBtn img {
  width: 14px;
  margin-left: 0.75rem;
  transition: 0.2s ease-in-out all;
}

#projectsBtn:hover img {
  margin-left: 1rem;
}

#projectsBtn:focus {
  background-color: var(--purple);
  box-shadow: none;
  border-radius: 0.15rem;
}

/* End of Track Record */

/* Why Choose Us */

.why-choose-container {
  background-color: var(--white);
  padding: 2.5rem 0 4rem;
}

.why-choose-container .wc-content {
  padding: 0;
  margin-left: 2rem;
}

.why-choose-container .wcu-left-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.why-choose-container .wc-content h4 {
  font-family: var(--font-bold);
  font-size: 3.2rem;
  color: var(--black-21);
  margin-bottom: 1rem;
  padding: 0;
}

.why-choose-container .wc-content h5 {
  font-family: var(--font-medium);
  font-size: 2.4rem;
  color: var(--purple);
}

.why-choose-container .wc-content p {
  font-family: inherit;
  font-size: 1.6rem;
  margin: 2.5rem 0;
  max-width: 80%;
}

.why-choose-container .wcu-right-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.why-choose-container .wcu-right-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../../images/box_bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.why-choose-container .wcu-right-img-wrap img {
  max-width: 70%;
}

.why-choose-us-points {
  background: url("../../../images/box_bg_wcu.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.why-choose-us-points .wcup-item-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8.15rem;
  width: 100%;
  max-width: 80%;
  margin: 7.8rem auto;
}

.why-choose-us-points .wcup-item-wrap .wcup-item {
  display: flex;
  align-items: flex-end;
  position: relative;
  text-align: right;
  padding-right: 10rem;
  min-height: 18rem;
  transition: 250ms ease;
}

/* First Box */

.why-choose-us-points .wcup-item-wrap .wcup-item.first:hover .wcup-img::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -3.5rem;
  z-index: 2;
  background: url("../../../images/why-choose-us/01_CC_selected.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 9.25rem;
  height: 9.25rem;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.first .wcup-text {
  position: relative;
}

.why-choose-us-points
  .wcup-item-wrap
  .wcup-item.first:hover
  .wcup-text::before {
  content: "";
  position: absolute;
  right: -1rem;
  width: 0.15rem;
  height: 100%;
  background: var(--purple);
}

/* Second Box */

.why-choose-us-points
  .wcup-item-wrap
  .wcup-item.second:hover
  .wcup-img::before {
  content: "";
  position: absolute;
  left: -0.25rem;
  bottom: -3.5rem;
  z-index: 2;
  background: url("../../../images/why-choose-us/02_PS_selected.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 9.25rem;
  height: 9.25rem;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.second .wcup-text {
  position: relative;
}

.why-choose-us-points
  .wcup-item-wrap
  .wcup-item.second:hover
  .wcup-text::before {
  content: "";
  position: absolute;
  left: -1rem;
  width: 0.15rem;
  height: 100%;
  background: var(--purple);
}

/* Third Box */

.why-choose-us-points .wcup-item-wrap .wcup-item.third:hover .wcup-img::before {
  content: "";
  position: absolute;
  right: 0.3rem;
  top: 0;
  z-index: 2;
  background: url("../../../images/why-choose-us/03_ES_selected.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 8.9rem;
  height: 8.9rem;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.third .wcup-text {
  position: relative;
}

.why-choose-us-points
  .wcup-item-wrap
  .wcup-item.third:hover
  .wcup-text::before {
  content: "";
  position: absolute;
  right: -1rem;
  width: 0.15rem;
  height: 100%;
  background: var(--purple);
}
/* Fourth Box */

.why-choose-us-points
  .wcup-item-wrap
  .wcup-item.fourth:hover
  .wcup-img::before {
  content: "";
  position: absolute;
  left: -0.6rem;
  top: 0;
  z-index: 2;
  background: url("../../../images/why-choose-us/04_QA_selected.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 9rem;
  height: 9rem;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.fourth .wcup-text {
  position: relative;
}

.why-choose-us-points
  .wcup-item-wrap
  .wcup-item.fourth:hover
  .wcup-text::before {
  content: "";
  position: absolute;
  left: -1.2rem;
  width: 0.15rem;
  height: 100%;
  background: var(--purple);
}

/* End of Boxes */

.why-choose-us-points .wcup-item-wrap .wcup-item::before {
  content: "";
  position: absolute;
  right: 100%;
  background: var(--light-70);
  width: calc(8rem + 5px);
  height: 0.01rem;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.second::before {
  width: calc(8rem + 1px);
}

.why-choose-us-points .wcup-item-wrap .wcup-item:first-of-type:before {
  display: none;
}

.why-choose-us-points .wcup-item-wrap .wcup-item::after {
  content: "";
  position: absolute;
  top: 100%;
  left: -2px;
  background: var(--light-70);
  width: 0.01rem;
  height: 8rem;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.first::after {
  left: 100%;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.invert {
  text-align: left;
  padding-left: 10rem;
  padding-right: 0;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.icon-top {
  align-items: flex-start;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.icon-top::after {
  display: none;
}

.why-choose-us-points .wcup-item-wrap .wcup-item h5 {
  font-family: var(--font-bold);
  font-size: 1.6rem;
  color: var(--black-21);
  margin: 1rem 0;
  transition: 300ms ease;
}

.why-choose-us-points .wcup-item-wrap .wcup-item:hover h5 {
  color: var(--purple);
}

.why-choose-us-points .wcup-item-wrap .wcup-item p {
  font-size: 1.6rem;
  color: var(--black-21);
}

.why-choose-us-points .wcup-item-wrap .wcup-item span {
  font-family: var(--font-italic);
  font-size: 1.6rem;
  color: var(--light-bd);
}

.why-choose-us-points .wcup-item-wrap .wcup-item img.wcu-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.invert img.wcu-icon {
  left: -0.2rem;
}

.why-choose-us-points .wcup-item-wrap .wcup-item.icon-top img.wcu-icon {
  top: 0;
}

/***** END OF LANDING PAGE  *****/

/* Media Queries */

@media only screen and (max-width: 767px) {
  /* Main Section */
  .main-container {
    margin-top: 0;
    min-height: 100%;
    overflow: hidden;
  }

  .main-container .main-section {
    margin: 0;
    overflow: hidden;
  }

  .main-container .main-section .video-bg {
    min-height: 100%;
  }
  p {
    line-height: 1.8;
    font-size: 0.96rem;
  }

  .main-section .content {
    background: none;
    padding-top: 40px;
  }

  .content .content-text {
    padding: 10rem 0;
  }

  .content .content-text h1,
  .content .content-text h2 {
    font-size: 2.4rem;
    line-height: 1.65;
  }

  .content .content-text h1 span::before {
    bottom: -0.3rem;
    right: -0.3rem;
  }

  .content h5.subtitle {
    width: 100%;
    max-width: 90%;
    font-size: 1.2rem;
    padding-top: 2rem;
  }

  /* About Section */

  .about-section {
    padding: 0;
    margin: 4rem 0 2rem;
    text-align: left;
  }

  .about-section .sec-header h4 {
    font-size: 1.75rem;
    margin-bottom: 0;
  }

  .about-section .sec-header h6 {
    font-size: 1.2rem;
    width: 100%;
    max-width: 100%;
    line-height: 1.7;
    margin-top: 1.5rem;
  }

  .about-section .services {
    padding: 20px 0;
  }

  .about-section .services img {
    padding-bottom: 1.75em;
  }

  .sec-header h6 {
    font-size: 16px;
    margin: 30px 0 20px;
    width: 100%;
    padding: 0;
  }

  .home-about-service-section {
    margin-bottom: 0;
  }

  .home-about-service-section .home-about-item {
    margin-bottom: 2.5rem;
  }

  .home-about-service-section .home-about-item h4 {
    font-size: 2rem;
  }

  /* Ready to Kickstart */

  .project-cta {
    position: relative;
    padding: 0;
    min-height: 100%;
  }

  .project-cta .project-stat-content {
    padding: 4rem 0;
  }

  .project-cta .project-stat-content h5 {
    font-size: 1.75rem;
  }

  .project-cta .project-stat-content h2 {
    font-size: 3rem;
    margin: 1.25rem 0 2rem;
  }

  .project-cta .proj-stat-text {
    position: relative;
    transform: translate(0);
    text-align: left;
  }

  /* Tabs */

  #services-tabs {
    gap: 20px;
    padding: 0;
  }

  #services-tabs .nav-item {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }

  #services-tabs .nav-item .nav-link::after {
    height: 0;
  }

  .home-service-wrap {
    padding: 40px 0 20px;
    max-height: 100%;
  }

  .home-service-wrap .hs-text {
    width: 100%;
  }

  .home-service-wrap img {
    width: 100%;
    margin: 25px 0 0;
  }
  .home-service-wrap .hs-text button.reach-us {
    margin: 10px 0;
  }

  .ms-btn-wrap {
    width: 100%;
    min-width: 100%;
  }

  button.more-services {
    width: 100%;
  }

  #services-tabs .nav-item.margin-down {
    margin-top: 0;
  }

  #services-tabs .nav-item.margin-up {
    margin-top: 0;
  }

  /* Project Section */

  .projects-section {
    margin: 3rem 0;
    padding: 0;
  }

  .projects-section h1 {
    font-size: 1.8rem;
    line-height: 1.6;
  }

  .projects-section .olw-wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .projects-section .olw-wrap .olw-left {
    margin-bottom: 3rem;
  }

  .projects-section .olw-wrap .olw-left h6 {
    font-size: 1.4rem;
    max-width: 100%;
  }

  .project-area-wrap .project-items-wrap .project-item {
    position: sticky;
    top: 10rem;
    padding: 5rem 0 0;
    overflow: hidden;
  }

  .project-area-wrap .project-items-wrap .project-item > .row {
    flex-direction: column-reverse;
  }

  .project-area-wrap .project-items-wrap .project-item .project-item-content {
    min-height: 50vh;
  }
  .project-area-wrap
    .project-items-wrap
    .project-item
    .project-item-content:last-child {
    min-height: 30vh;
  }

  .project-area-wrap
    .project-items-wrap
    .project-item
    .project-item-content
    .project-intersection-line {
    margin-bottom: 2rem;
  }

  .project-area-wrap
    .project-items-wrap
    .project-item
    .project-item-content
    span {
    font-size: 1.2rem;
  }

  .project-area-wrap .project-items-wrap .project-item .project-item-content p {
    max-width: 100%;
  }

  .project-area-wrap
    .project-items-wrap
    .project-item
    .project-item-content
    h4 {
    font-size: 2.2rem;
  }

  .project-area-wrap {
    position: sticky;
    top: 10rem;
  }

  .project-area-wrap .project-item-col {
    margin-top: 6rem;
    max-height: 50vh;
    overflow-y: auto;
  }

  /* Newsletter */

  .newsletter-section {
    padding: 2rem 0 8rem;
  }

  .newsletter-section .news-content-wrap {
    padding: 0;
  }

  .newsletter-section .news-text-wrap h4 {
    font-size: 2.5rem;
  }

  .newsletter-section .news-text-wrap h5 {
    font-size: 1.75rem;
  }

  .newsletter-section .news-text-wrap p,
  .newsletter-section .news-subscribe-wrap p {
    max-width: 100%;
  }

  .newsletter-section .news-subscribe-wrap {
    max-width: 100%;
  }

  .newsletter-section .news-subscribe-wrap .nwsub-input-wrap input {
    border-right: 1px solid var(--light-9e);
  }

  .newsletter-section .news-subscribe-wrap .nwsub-input-wrap {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .newsletter-section .news-subscribe-wrap .nwsub-input-wrap button {
    min-width: auto;
    color: var(--black-21);
  }

  .newsletter-section .news-subscribe-wrap .nwsub-input-wrap button:focus {
    color: var(--white);
  }

  /* Track Record */

  .our-stats {
    margin-top: -8rem;
    background: var(--white);
    padding-top: 5rem;
  }

  .ourstats-container {
    padding: 1rem;
  }

  .ourstats-container h2 {
    font-size: 4rem;
  }

  .ourstats-container p {
    max-width: 100%;
    line-height: 1.5;
  }

  .stat-box {
    flex-direction: column;
  }

  .stat-box h6 {
    max-width: 85%;
  }

  .stat-box img.stat-big-img.projects-done {
    width: 55%;
    transform: translate(80%, 0);
  }

  .stat-box img.stat-big-img.happy-clients {
    margin: 3.5rem 0 0;
  }

  /* Why Choose us */

  .why-choose-container .wc-content {
    padding: 0;
    margin: 0;
  }

  .why-choose-container {
    padding: 0.5em 0 2em;
  }

  .why-choose-container .wc-content h5 {
    font-size: 1.8rem;
  }

  .why-choose-container .wc-content p {
    max-width: 100%;
  }

  .why-choose-us-points .wcup-item-wrap .wcup-item h5 {
    font-size: 1.4rem;
  }

  .why-choose-us-points .wcup-item-wrap .wcup-item p {
    font-size: 1.25rem;
    line-height: 1.6;
  }

  .why-choose-container .wcu-right-img-wrap img {
    max-width: 100%;
  }

  .why-choose-us-points .wcup-item-wrap {
    flex-direction: column;
  }

  .why-choose-us-points .wcup-item-wrap .wcup-item::after,
  .why-choose-us-points .wcup-item-wrap .wcup-item::before {
    display: none;
  }

  .why-choose-us-points
    .wcup-item-wrap
    .wcup-item.second:hover
    .wcup-img::before {
    height: 9.2rem;
  }

  /* Projects */

  .projects-section .project-item {
    padding: 1.25em;
  }

  .projects-section .project-item p {
    font-size: 1em;
  }

  .project-area-wrap .project-dets-content p {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .main-container .main-section {
    margin-bottom: 0;
  }
  .main-container .main-section .video-bg {
    min-height: 100%;
  }
  .main-section .content {
    padding-top: 15rem;
  }
  .content .content-text h1,
  .content .content-text h2 {
    font-size: 5rem;
  }

  .home-about-service-section {
    margin: 2rem 0;
  }

  .home-about-service-section .home-about-item {
    margin-bottom: 3rem;
  }

  .about-section .sec-header h6 {
    max-width: 100%;
  }

  .main-container {
    min-height: 100%;
  }

  /* Latest Work */

  .projects-section {
    margin-bottom: 0;
  }

  /* About */

  .about-section {
    margin: 2rem 0;
  }

  .why-choose-container .wcu-items-wrap {
    width: 100%;
  }

  /* Kickstart */

  .project-cta {
    min-height: 100%;
  }

  .project-cta .project-stat-content {
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: 50px 0 0;
  }

  .project-cta .project-stat-content h2 {
    font-size: 4rem;
  }

  .project-cta .proj-stat-text {
    padding: 3rem;
    transform: translate(-3rem, 6rem);
  }

  /* Projects Section */

  .projects-section h1 {
    font-size: 2.25rem;
  }

  .projects-section .olw-wrap .olw-left {
    max-width: 65%;
  }

  .project-area-wrap
    .project-items-wrap
    .project-item
    .project-item-content
    h4 {
    font-size: 2rem;
  }

  .project-area-wrap
    .project-items-wrap
    .project-item
    .project-item-content
    h6 {
    font-size: 1.45rem;
  }

  .project-area-wrap .project-items-wrap .project-item .project-item-content p {
    font-size: 1.35rem;
    max-width: 90%;
  }

  .project-area-wrap .project-items-wrap .project-item {
    padding-bottom: 3rem;
  }

  .project-area-wrap .project-items-wrap .project-item .project-item-content {
    min-height: 50vh;
  }

  /* Track Record */

  .ourstats-container {
    padding-bottom: 0;
  }

  .ourstats-container h2 {
    font-size: 5rem;
  }

  .ourstats-container p {
    width: 100%;
    max-width: 100%;
  }
  .stat-box img.stat-big-img.projects-done {
    transform: translate(0);
  }

  /* Why Choose Us */

  .why-choose-container .wc-content {
    margin: 0;
  }

  .why-choose-container .wc-content h5 {
    font-size: 1.6rem;
  }
  .why-choose-container .wc-content p {
    max-width: 100%;
  }
  .why-choose-us-points .wcup-item-wrap {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .main-section .content .devship-text-effect.dev {
    top: 80px;
    right: -108px;
  }

  .main-section .content .devship-text-effect.ship {
    bottom: 130px;
    left: -120px;
  }

  /* About */
  .about-us .about-img {
    padding: 0 !important;
    max-width: 100% !important;
  }

  /* Latest Work */

  .project-area-wrap .project-dets-content p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1500px) {
  .project-area-wrap .project-items-wrap .project-item .project-item-content {
    min-height: 65vh;
  }
}

@media only screen and (min-width: 1600px) {
  .main-section .content {
    padding-top: 17rem;
  }

  .main-section .content-text {
    padding-top: 5rem;
  }
  .main-container {
    background: var(--white);
    max-height: calc(100vh + 4rem);
    padding-bottom: 0;
  }
  .main-container .main-section {
    height: 100%;
    min-height: 100vh;
  }
  .main-container .main-section .video-bg {
    height: 100vh;
    min-height: 100vh;
    padding: 0;
  }
  .project-area-wrap
    .project-items-wrap
    .project-item
    .project-item-content:last-child {
    min-height: 35vh;
  }
}

@import url("../App.css");

/* Preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.preloader.pre-hide {
  visibility: hidden;
  opacity: 0;
}

.preloader-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
}

.preloader-content img {
  max-width: 100%;
  min-width: 8rem;
}

.preloader-content #pre {
  width: 4rem;
  margin-top: 1.5rem;
}

.preloader-content h6 {
  font-family: var(--font-medium);
  color: var(--dark-77);
  text-transform: uppercase;
}

.preloader-content .pre-line {
  width: 100%;
  max-width: 8rem;
  height: 0.4rem;
  background: var(--light-ee);
  margin: 1.5rem 0;
  border-radius: 0.25rem;
  overflow: hidden;
}

.preloader-content .pre-line .pre-fill-line {
  width: 0;
  height: 100%;
  background: var(--purple);
  animation: progress 2000ms 200ms linear forwards;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
/* Navbar */

.navbar {
  padding: 2rem 0;
  transition: 300ms ease;
}

/* .navbar.scrolled {
  background-color: var(--white) !important;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
} */

.navbar .container {
  padding-bottom: 0;
  /* border-bottom: 1px solid var(--light-e0); */
}

.nav-item #contactLink {
  position: relative;
  z-index: 2;
  background: none;
  padding: 5px 15px;
  color: var(--black-21);
  border: 1px solid currentColor;
  transition: 250ms ease;
}

.nav-item #contactLink:hover {
  border-color: var(--purple);
  background: var(--purple);
  color: var(--white);
}

.nav-item .nav-link.active#contactLink {
  border-color: var(--purple);
  background: var(--purple);
  color: var(--white);
}

/* Call to Action */

.cta {
  position: relative;
  z-index: 2;
  background-image: linear-gradient(
    to left,
    rgba(67, 233, 123, 0.65) 0%,
    rgba(56, 249, 215, 0.5) 100%
  );
  padding: 3.5rem 0;
}

/* .cta::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: url("../../../images/HomeEl.svg");
  background-size: 90%;
  background-position: top center;
  width: 100%;
  height: 100%;
  opacity: 0.85;
} */

.cta-content {
  position: relative;
  z-index: 2;
}

.cta #cta-tag {
  display: inline-block;
  font-family: var(--font-bold);
  text-transform: uppercase;
  color: var(--purple-1);
  background-color: var(--white);
  padding: 0.25em 0.65em;
  border-radius: 0.35em;
  margin-bottom: 1em;
  letter-spacing: 0.15em;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
}

.cta .contact-us-btn {
  display: flex;
  align-items: center;
  gap: 7px;
  background-color: var(--black);
  border: none;
  color: var(--white);
  margin-top: 1.75em;
}

.cta .contact-us-btn img {
  width: 16px;
  transition: 0.2s ease-in-out transform;
}
.cta .contact-us-btn:hover img {
  transform: translate(3px, -3px);
}

.cta img {
  display: block;
  max-width: 80%;
  width: 25rem;
  margin: 0 auto;
}

.cta .cta-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 95%;
  margin: 3em 0 0;
}

.cta .cta-form .form-box {
  position: relative;
  width: 100%;
}

.cta .cta-form .form-box input.form-control {
  font-family: var(--font-medium);
  font-size: 1.1em;
  background: none;
  border: none;
  border-bottom: 2px solid var(--dark-332);
  border-radius: 0px;
  color: var(--black);
  padding-left: 0;
}

.cta .cta-form .form-box input.form-control::placeholder {
  color: currentColor;
  position: relative;
  top: 0;
}

.cta .cta-form .form-box label {
  font-family: var(--font-medium);
  font-size: 1.1em;
  position: absolute;
  top: 6px;
  transition: 0.15s ease-in-out all;
}

.cta .cta-form .form-box input.form-control:focus + label,
.cta .cta-form .form-box input.form-control:not([value=""]) ~ label {
  font-size: 0.9em;
  top: -15px;
  letter-spacing: 0.05em;
}

/* Form Overlay */

.form-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100vh;
  background: var(--white);
  visibility: hidden;
  opacity: 0;
  transform-origin: top;
  transition: all 0.4s ease-in-out;
  /* background-image: url("../../../images/HomeEl.svg"); */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -100px;
  background-color: #fefefe;
}

.form-overlay.form-open {
  visibility: visible;
  opacity: 1;
}

.form-overlay-content {
  display: block;
  margin: 0 auto;
  width: 80%;
  height: 70vh;
  padding: 4rem 0;
  overflow: hidden;
}

.form-overlay-content .form-slide-container {
  width: 100%;
  height: 100%;
  /* background: #fafafa; */
  padding: 2rem;
}

.form-box-container {
  height: 400%;
}

.form-slide-container .form-box {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.85s ease-in-out all;
  top: 0;
}

.form-slide-container .form-box.move-up {
  top: -100%;
}

.form-slide-container .form-box.move-up-2 {
  top: -200%;
}

.form-slide-container .form-box.move-up-3 {
  top: -300%;
}

.form-slide-container .form-box input {
  border: none;
  border-bottom: 2px solid var(--black);
  border-radius: 0px;
  padding: 0.35rem 0;
  margin: 1.2rem 0;
  font-size: 1.25rem;
  color: var(--dark-332);
  font-family: var(--font-medium);
  background: none;
}

#project_textarea {
  border: 2px solid var(--black);
  padding: 1.2rem;
  margin: 1.5rem 0 0.25rem 0;
}

.first-section h3 {
  font-family: var(--font-medium);
  font-size: 1.35rem;
  margin: 0.75rem 0;
  color: var(--dark-55);
}

.close_form_btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.form-slide-container .form-box .form-btn {
  display: flex;
  align-items: center;
  font-size: 1.15rem;
  border: none;
  background-color: var(--dark-33);
  padding: 0.35rem 1.2rem;
  transform: translateY(20px);
}

.form-slide-container .form-box .form-btn img {
  width: 16px;
  margin-left: 0.75rem;
}

.close_form_btn span {
  font-size: 1rem;
  margin-right: 0.5rem;
  color: var(--bs-secondary);
}

/* End of Form Overlay */

/* Testimonials */

section.testimonial-section {
  position: relative;
  text-align: center;
}

section.testimonial-section .testimonial-content {
  position: relative;
  padding: 2rem 0;
  z-index: 2;
}

section.testimonial-section .testi-left {
  text-align: center;
}

section.testimonial-section .testi-left .testi-cta-img {
  background: url("../../../images/box_bg_testi.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

section.testimonial-section .testi-left h4 {
  font-family: var(--font-bold);
  font-size: 2.4rem;
  color: var(--black-21);
  text-align: center;
  margin: 0 auto;
  color: var(--black-21);
}

section.testimonial-section .testi-left h5 {
  font-family: var(--font-bold);
  font-size: 1.8rem;
  color: var(--black-21);
  margin: 0 auto 1rem;
  width: 70%;
}

section.testimonial-section .testi-left h6 {
  font-family: var(--font-regular);
  font-size: 1.6rem;
  margin: 0 auto;
  width: 50%;
}

section.testimonial-section .testi-left img.cta-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52rem;
  max-width: 100%;
  margin: 5rem auto;
  text-align: center;
}

.testimonial-area-wrap {
  position: relative;
  padding: 5rem 0 2.5rem;
  width: 100%;
  max-width: 70%;
  margin: 7rem auto;
  min-height: 28rem;
}

.testimonial-area-wrap::before {
  content: "";
  position: absolute;
  top: 20px;
  left: -150px;
  background: url("../../../images/quote-left.svg");
  background-size: 4.8rem;
  width: 4.8rem;
  height: 4.8rem;
}

.testimonial-area-wrap::after {
  content: "";
  position: absolute;
  bottom: -20px;
  right: -150px;
  background: url("../../../images/quote-right.svg");
  background-size: 4.8rem;
  width: 4.8rem;
  height: 4.8rem;
}

.testi-content-wrap {
  padding: 0;
}

.testimonial-area-wrap h2 {
  font-family: var(--font-bold);
  font-size: 5.6rem;
  text-align: left;
  line-height: 1.15;
  margin: -5rem 0 0;
}
.testimonial-area-wrap .testi-client-item-wrap {
  position: relative;
  height: 100%;
  min-height: 28rem;
  margin: 0.25rem;
}

.testimonial-area-wrap
  .testi-client-item-wrap
  .owl-carousel.owl-drag
  .owl-item {
  width: 100%;
}

.testimonial-area-wrap
  .testi-client-item-wrap
  .owl-carousel.owl-drag
  .owl-nav
  button {
  width: 2.8rem;
  height: 2.8rem;
  margin: 1rem;
}

.testimonial-area-wrap
  .testi-client-item-wrap
  .owl-carousel.owl-drag
  .owl-nav
  button:hover {
  background: none;
  color: var(--black-21);
}

.testimonial-area-wrap
  .testi-client-item-wrap
  .owl-carousel.owl-drag
  .owl-nav
  span {
  position: relative;
  font-size: 2.25rem;
  color: transparent !important;
}

.testimonial-area-wrap
  .testi-client-item-wrap
  .owl-carousel.owl-drag
  .owl-nav
  .owl-next
  span::before {
  content: "";
  position: absolute;
  background: url("../../../images/icons/testi-up.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 100%;
  transform: rotate(90deg);
  margin-left: -0.5rem;
}

.testimonial-area-wrap
  .testi-client-item-wrap
  .owl-carousel.owl-drag
  .owl-nav
  .owl-prev
  span::before {
  content: "";
  position: absolute;
  background: url("../../../images/icons/testi-down.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 100%;
  transform: rotate(90deg);
  margin-left: -0.5rem;
}

.testimonial-area-wrap .testi-client-item {
  width: 100%;
  padding: 1.8rem;
  border: 1px solid var(--black-21);
  text-align: left;
  max-width: 100%;
  margin: 0;
  min-height: 28rem;
  background: var(--white);
  transition: 350ms ease;
}

.testimonial-area-wrap .testi-client-item h4 {
  font-family: var(--font-medium);
  font-size: 1.6rem;
  color: var(--black-21);
  margin-bottom: 1.25rem;
}

.testimonial-area-wrap .testi-client-item h6 {
  font-family: var(--font-italic);
  font-size: 1.5rem;
  color: var(--black-21);
}

.testimonial-area-wrap .testi-client-item h5 {
  font-family: var(--font-bold);
  font-size: 1.6rem;
  color: var(--black-21);
  text-align: right;
  margin: 2.5rem 0 0;
}

.testimonial-area-wrap .testi-item-controls {
  display: flex;
  display: none;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  z-index: 2;
  top: 15rem;
  right: -3.5rem;
}

.testimonial-area-wrap .testi-item-controls span.testi-control {
  cursor: pointer;
}

/* Assistance */

.common-cta-down {
  width: 100%;
  max-width: 60%;
  margin: 3rem auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background: url("../../../images/box_bg_testi.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.common-cta-down .cta-down-text h2 {
  font-size: 2.4rem;
  color: var(--black-21);
  margin: 0 0 15px 0;
}

.common-cta-down .cta-down-text h6 {
  margin: 0 0 5px 0;
  font-size: 1.6rem;
  line-height: 1.65;
  max-width: 95%;
}

/* Floating Menu Styles */

.floating-menu {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1000;
  padding: 0;
  margin: 0;
  background: none;
}

.floating-menu .fl-items {
  position: relative;
  display: flex;
  align-items: end;
  padding: 15px;
  width: 45px;
  height: 45px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
}

.floating-menu .fl-items:hover {
  height: 144px;
  transition: 0.2s ease-out all;
}

.floating-menu .fl-items:hover .fl-exp {
  visibility: visible;
  opacity: 1;
}

.floating-menu .fl-items img {
  transition: 0.18s ease-in-out all;
}

.floating-menu .fl-items:hover img {
  transform: rotate(270deg);
}

.floating-menu .fl-items h6.fl-exp {
  font-family: var(--font-regular);
  font-size: 16px;
  position: absolute;
  bottom: 68px;
  left: -19px;
  transform: rotate(270deg);
  white-space: nowrap;
  margin: 0;
  color: var(--white);
  visibility: hidden;
  opacity: 0;
  transition: 0.18s ease all;
}

.floating-menu .fl-items.primary {
  position: relative;
  background: var(--purple);
  border-radius: 0 8px 0 0;
}

.floating-menu .fl-items.purple {
  position: relative;
  background: #8d6eb1;
}

.floating-menu .fl-items.purple .fl-exp {
  left: -9px;
  bottom: 60px;
}

.floating-menu .fl-items.lk {
  position: relative;
  background: #016698;
}

.floating-menu .fl-items.lk .fl-exp {
  left: -9px;
  bottom: 60px;
}

.floating-menu .fl-items.wa {
  position: relative;
  background: #2cb440;
  border-radius: 0 0 8px 0;
}

.floating-menu .fl-items.wa .fl-exp {
  left: -16px;
  bottom: 68px;
}

/* End of Floating Menu Styles */

/* Footer */

footer {
  position: relative;
  padding: 40px 0 20px;
  margin: 4em 0 0;
  background-color: var(--light-fa);
}

footer h6 {
  font-family: var(--font-bold);
  font-size: 1.8rem;
  margin: 12px 0 0;
}

footer h6[data-title] {
  font-size: 1.2rem;
  color: var(--light-9e);
  text-transform: uppercase;
  letter-spacing: normal;
  margin-bottom: 15px;
}

footer p[data-tagline] {
  font-size: 12px;
  color: var(--light-75);
}

footer p {
  font-size: 1em;
  font-family: var(--font-regular-2);
  line-height: 1;
}

footer .footer-links li {
  padding-bottom: 12px;
}

footer .footer-links li a {
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--black);
  transition: 0.1s ease-in-out all;
}

footer .footer-links li a:hover {
  color: var(--purple);
}

footer .footer-contact-details {
  position: relative;
  padding: 20px 0 0;
}

footer .footer-contact-details::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 6rem;
  height: 0.1rem;
  background-color: var(--light-ee);
}

footer .footer-contact-details img {
  width: 16px;
  margin-right: 3px;
  padding: 0;
}

footer .footer-contact-details p {
  font-family: var(--font-medium);
  font-size: 16px;
  padding-bottom: 15px;
  margin: 0;
}

footer .footer-contact-details p.addr-text {
  padding: 15px 0 0;
  min-height: 95px;
}

footer .footer-contact-details p a {
  display: inline-flex;
  font-family: var(--font-regular);
  font-size: 14px;
  align-items: center;
  gap: 10px;
  color: var(--black);
  line-height: 1.75;
}

footer .social-links {
  display: flex;
  align-items: center;
  gap: 15px;
}

footer .social-links li img {
  max-width: 100%;
  width: 1.65em;
}

footer .copy-text {
  border-top: 1px solid var(--light-ee);
  width: 100%;
  padding: 20px 0 0;
  margin: 20px 0 0;
  text-align: center;
}

footer .copy-text p {
  font-family: var(--font-medium);
  font-size: 1.5rem;
  color: var(--black-21);
  margin: 0 0 10px 0;
}

footer .copy-text span {
  font-family: var(--font-regular);
  font-size: 1.3rem;
  color: var(--purple);
}

footer .nav-tabs {
  display: inline-flex;
  border-bottom: none;
  background: var(--light-ee);
  padding: 4px;
  border-radius: 8px;
}

footer .nav-item {
  padding-right: 10px;
}

footer .nav-item .nav-link {
  border: none;
  background: none;
  padding: 3px 15px;
  border-radius: 5px;
  color: var(--light-75);
  cursor: pointer;
}

footer .nav-item .nav-link.active {
  border: none;
  background: var(--purple);
  color: var(--white);
}

/* Go To Top Button */

.go_top_wrap {
  position: fixed;
  right: 0;
  bottom: 3rem;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  transform: translateX(-50%);
  z-index: 999;
  display: inline-block;
  background-color: var(--purple);
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 300ms ease;
}

.go_top_wrap.show {
  opacity: 0.25;
  visibility: visible;
  pointer-events: auto;
}

.go_top_wrap:hover {
  opacity: 1;
}

.go_top {
  width: 100%;
  height: 100%;
  padding: 0.55em;
}

.rotate_load {
  animation: rotate 0.5s ease-in-out infinite forwards;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* End of Footer */

/* Project Header */

.project-header {
  position: relative;
  width: 100%;
  padding: 4em 0 5em 0;
  background-color: var(--purple-light);
  /* background-image: url("../../../images/HomeEl.svg"); */
  background-size: cover;
  background-repeat: no-repeat;
  clip-path: polygon(0 0, 100% 0%, 100% 97%, 0 85%);
}

.project-header h3 {
  font-size: 1.35rem;
  margin: 0.65em 0 0;
}

.pro-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3em;
}

.project-header-img {
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  right: 2em;
  z-index: 2;
  width: 170px;
}

.project-header-img img {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

/* Common Styles */

/* Bootstrap override */

.navbar-light .navbar-nav .nav-link {
  padding: 0.25rem 0.5rem;
  color: var(--dark-33);
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  position: relative;
  color: var(--dark-33);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.95);
}

.navbar-light .navbar-nav .nav-link:last-child.active {
  font-family: var(--font-bold);
  color: var(--purple);
}
/* 
.navbar-light .navbar-nav .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 22px;
  height: 2px;
  background: var(--purple);
} */

/* .navbar-light .navbar-nav .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: (0 + 8px);
  width: 25px;
  height: 3px;
  background-color: var(--purple-1);
} */

.nav-item {
  font-size: 1.5rem;
  padding-inline: 1.25rem;
}

.nav-item:last-child {
  padding-right: 0;
}

.container {
  padding-inline: 0;
}

/* Divider */

.section-divider {
  display: block;
  width: 100%;
  max-width: 569px;
  margin: 20px auto;
  background: rgba(141, 110, 177, 0.5);
  height: 1px;
}

/* Common Utilities */

.reach-us {
  position: relative;
  font-family: var(--font-bold);
  font-size: 16px;
  background: var(--white);
  color: var(--purple);
  padding: 7px 25px;
  margin: 20px 0 0;
  border: 1px solid currentColor;
  border-radius: 0px;
  box-shadow: 0 6px 16px 0 rgba(82, 54, 149, 0.16);
  cursor: pointer;
  z-index: 1;
  min-width: 128px;
}

.reach-us:hover {
  color: var(--white);
  border: 1px solid var(--purple);
}

.reach-us::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 4px;
  height: 100%;
  background: var(--purple);
  transition: 0.2s ease-in-out width;
}

.reach-us::after {
  content: "";
  position: absolute;
  top: 0;
  right: -4px;
  z-index: -1;
  width: 4px;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background: var(--purple-light);
  transition: 0.2s ease-in-out all;
}

.reach-us:hover::before {
  width: calc(100% - 4px);
}

.reach-us:hover::after {
  right: 0;
  opacity: 1;
  visibility: visible;
}

.reach-us.linkedin {
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: var(--font-regular);
  font-size: 14px;
  box-shadow: 0 6px 16px 0 rgba(0, 119, 181, 0.16);
  color: #0077b5;
}

.reach-us.linkedin:hover {
  border-color: #0077b5;
  color: var(--white);
}

.reach-us.linkedin::before {
  background: #0077b5;
}

.reach-us.linkedin::after {
  background: #0293e0;
}

button.common-btn {
  font-family: var(--font-regular);
  font-size: 1.6rem;
  color: var(--black-21);
  display: flex;
  align-items: center;
  gap: 25px;
  background: none;
  border: 1px solid var(--black-21);
  padding: 1.1rem 1.4rem;
  max-height: 4.8rem;
  transition: 300ms ease all;
}

button.common-btn.white-btn {
  border-color: var(--white);
  color: var(--white);
}

button.common-btn .common-btn-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;
  max-width: 2.4rem;
  max-height: 2.4rem;
  background: var(--black-21);
  transform: scale3d(1, 1, 1);
  transform-origin: center;
  transition: 300ms transform ease;
}

button.common-btn.white-btn .common-btn-img {
  background: var(--white);
}

button.common-btn:hover .common-btn-img {
  position: relative;
  transform: scale3d(1.3, 1.3, 1.3);
  background: var(--white);
}

button.common-btn:hover .common-btn-img::before {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../../images/right_arrow_purple.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  width: 16px;
  height: 14px;
}

button.common-btn:hover {
  background: var(--purple);
  color: var(--white) !important;
  border-color: var(--purple);
}

button.common-btn:hover img {
  display: none;
}

button[data-know] {
  position: relative;
  font-family: var(--font-bold);
  font-size: 16px;
  background: none;
  color: var(--purple);
  border: none;
  border-radius: 0px;
  padding-block: 8px;
  padding-inline: 0;
  margin: 20px 0 0;
  transition: all 0.18s ease-in-out;
}

button[data-know]:hover {
  background: var(--black);
  color: var(--white);
  padding-inline: 20px;
}

button[data-know]::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background: var(--purple);
}

button[data-know]:hover::before {
  background: none;
}

/* Next Section Line */

.next-section-line-wrap {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 4rem 0;
  padding: 0;
}

.next-section-line-wrap .nsl-line {
  width: 0.2rem;
  height: 5.6rem;
  background: var(--purple);
  transform: translateY(-16px);
}

.next-section-line-wrap.white .nsl-line {
  position: relative;
  z-index: -1;
  background: var(--white);
}

.next-section-line-wrap .nsl-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  min-width: 3.2rem;
  min-height: 3.2rem;
  max-width: 3.2rem;
  max-height: 3.2rem;
  border-radius: 50%;
  background: rgba(94, 0, 204, 0.04);
  animation: scrolling 2500ms ease infinite both;
}

.next-section-line-wrap.white .nsl-circle {
  background: rgba(255, 255, 255, 0.1);
  animation: scrolling 2500ms ease infinite both;
}

.next-section-line-wrap .nsl-circle .nsl-circle-small {
  width: 0.8rem;
  height: 0.8rem;
  min-width: 0.8rem;
  min-height: 0.8rem;
  max-width: 0.8rem;
  max-height: 0.8rem;
  border-radius: 50%;
  background: var(--purple);
}

.next-section-line-wrap .nsl-circle .nsl-circle-small {
  border: 1px solid var(--white);
}

@keyframes scrolling {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5.6rem);
  }
  100% {
    transform: translateY(0);
  }
}

/* @keyframes scaleSize {
  0% {
    width: 0.8rem;
    height: 0.8rem;
  }
  50% {
    width: 1.2rem;
    height: 1.2rem;
  }
  100% {
    width: 0.8rem;
    height: 0.8rem;
  }
} */

/* End of Next Section Line */

/* Select Box Common Styles */

.select_box__control {
  font-family: var(--font-regular);
  border-radius: 0 !important;
  border-color: var(--light-9e) !important;
  cursor: pointer !important;
}

.select_box__control:focus {
  box-shadow: none !important;
}

.select_box__indicator-separator {
  display: none !important;
}

.select_box__menu-list .select_box__option {
  font-size: 1.4rem !important;
  cursor: pointer !important;
}

.select_box__option--is-focused {
  background: rgba(94, 0, 204, 0.06) !important;
}

.select_box__control--is-focused {
  box-shadow: none !important;
  border-color: var(--black-21) !important;
}

.select_box__option--is-selected {
  background: rgba(94, 0, 204, 0.2) !important;
  color: var(--black) !important;
}

.select_box__single-value,
.select_box__input-container {
  font-size: 1.4rem !important;
}

.select_box__placeholder {
  font-size: 1.4rem !important;
}

/* End of Select Box Common Styles */

@media (min-width: 768px) and (max-width: 1200px) {
  .go_top_wrap {
    right: 1rem;
  }
}

@media (min-width: 1401px) and (max-width: 1600px) {
  .form-overlay {
    background-size: cover;
    background-position: 30px -130px;
  }

  .form-overlay-content {
    height: 90vh;
  }
}

@media only screen and (max-width: 767px) {
  .navbar {
    padding: 0;
    border-bottom: none;
  }

  .navbar ul {
    align-items: flex-start !important;
    margin-top: 1.5em !important;
    padding: 1.5rem 1rem;
    border-radius: 0 0 8px 8px;
    border-top: 1px solid var(--light-ee);
    background: var(--white);
  }

  .navbar ul li {
    padding-bottom: 10px;
  }

  .navbar-light .navbar-nav .nav-link {
    padding: 0 2px 5px;
  }

  .navbar-brand img {
    width: 100px;
  }

  .navbar-light .navbar-nav .nav-link.active::before {
    left: 0;
    transform: translate(3px);
  }

  .navbar-light .navbar-toggler {
    padding: 0.75rem 1rem;
    margin: 0;
    border-radius: 8px;
    border-color: var(--black-21);
  }

  .navbar-toggler-icon {
    width: 1.4rem;
    height: 1.4rem;
  }

  .nav-item #contactLink {
    margin: 1.5rem 0 0;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("../../../images/hammenu.svg");
  }

  .container {
    padding: 10px 15px;
  }

  .navbar .container {
    padding: 2rem 1.5rem;
    border: none;
  }

  .floating-menu {
    display: none;
  }

  /* CTA */

  .cta {
    padding-inline: 1em;
  }

  #cta-tag {
    font-size: 0.8em;
  }

  .cta .cta-form {
    flex-direction: column;
    width: 100%;
  }

  .cta .cta-form .form-box {
    padding-bottom: 1.25em;
  }

  .cta .contact-us-btn {
    margin: 1.45em auto 0;
  }

  .cta .image-holder {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 3.5rem 0 0;
  }

  .form-overlay {
    background-size: cover;
    background-position: -70px bottom;
  }

  .form-overlay-content {
    width: 100%;
    height: 95vh;
  }

  .form-overlay-content .form-slide-container {
    padding: 1rem;
  }

  .form-slide-container .form-box .form-btn {
    font-size: 0.95rem;
  }

  .first-section h3 {
    font-size: 1.15rem;
  }

  .first-section h6 {
    font-size: 0.9rem;
  }

  #project_textarea {
    padding: 0.75rem;
  }

  /* Assistance */

  .common-cta-down {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    margin: 0;
    padding: 2rem;
  }

  .common-cta-down .cta-down-text h6 {
    max-width: 100%;
  }

  .common-cta-down img.asst-img {
    width: 100%;
    max-width: 80%;
    margin: 4rem 0 0;
  }

  .common-cta-down h6 {
    font-size: 1.05em;
  }

  .common-cta-down .price {
    padding: 2rem 0;
  }

  .common-cta-down-text {
    width: 100%;
    margin: 2rem 0 0;
  }

  /* Testimonial */

  section.testimonial-section {
    position: relative;
    padding: 4em 0.25em 2em;
    background-color: var(--light-fd);
  }

  section.testimonial-section .testimonial-content {
    padding: 0;
  }

  section.testimonial-section .testi-left p {
    width: 100%;
    padding: 10px;
  }

  .testimonial-area-wrap {
    width: 100%;
    padding: 5rem 0 0;
    max-width: 90%;
  }

  .testimonial-area-wrap h2 {
    font-size: 2rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .testi-content-wrap {
    padding: 10px 12px;
  }

  section.testimonial-section p {
    font-size: 1.05em;
  }

  section.testimonial-section .testi-left button.contact-btn {
    margin: 1em 0 1.5em 0;
  }

  .testimonial-section .testimonial-wrap {
    width: 100%;
    height: 100%;
    padding: 1.25em 0;
  }

  .testimonial-area-wrap .testi-client-item {
    padding: 1.25rem;
  }

  .testimonial-area-wrap .testi-client-item h6 {
    font-size: 1.25rem;
    line-height: 1.35;
  }

  .testimonial-area-wrap .testi-client-item h5 {
    font-size: 1.4rem;
  }

  section.testimonial-section .testi-left {
    padding: 1rem;
  }

  section.testimonial-section .testi-left h4 {
    font-size: 1.8rem;
  }

  section.testimonial-section .testi-left h5 {
    font-size: 1.4rem;
  }

  section.testimonial-section .testi-left h6 {
    font-size: 1.25rem;
    width: 100%;
    max-width: 100%;
  }

  .testimonial-area-wrap::before {
    top: -3rem;
    left: -1rem;
  }

  .testimonial-area-wrap::after {
    bottom: 0;
    right: -1rem;
  }

  /* Footer */

  footer {
    padding: 30px 15px 20px;
    margin: 20px 0 0;
  }

  footer .container {
    padding: 0;
  }

  footer p {
    width: 100%;
  }
  footer .copy-text {
    text-align: center;
  }

  footer .copy-text p {
    font-size: 12px;
    letter-spacing: normal;
  }

  footer .copy-text span {
    font-size: 10px;
    line-height: 1;
  }

  .go_top_wrap {
    bottom: 10rem;
    right: 0;
  }

  /* Button */

  button.common-btn {
    font-family: inherit !important;
    font-size: 1.5rem;
    padding: 0.75rem 1rem;
  }

  .reach-us {
    font-size: 14px;
    min-width: 100px;
  }

  /* Divider */
  .section-divider {
    width: calc(80% - 40px);
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .navbar-brand img {
    max-width: 70%;
  }
  .navbar-light .navbar-nav .nav-item {
    padding-right: 1rem;
    padding-left: 0;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 1rem;
  }

  /* Testimonials */

  section.testimonial-section {
    margin: 2rem 0 0;
  }
  .testimonial-area-wrap {
    width: 100%;
    max-width: 100%;
    margin-bottom: 3rem;
  }

  .testimonial-area-wrap h2 {
    font-size: 3rem;
    margin: 0 0 4rem 0;
  }

  img {
    max-width: 100%;
  }

  section.testimonial-section .testi-left h6 {
    width: 100%;
    max-width: 100%;
  }

  .testimonial-area-wrap::before {
    top: 0;
    left: 0;
  }

  .testimonial-area-wrap::after {
    bottom: 0;
    right: 0;
  }

  .common-cta-down {
    width: 100%;
    max-width: 100%;
    padding: 2rem;
  }

  .common-cta-down img.asst-img {
    max-width: 40%;
  }

  .go_top_wrap {
    right: 0;
    bottom: 1.5rem;
  }

  button.common-btn {
    font-size: 1.2rem;
    padding: 0.75rem 1rem;
  }
}

@media only screen and (min-width: 1600px) {
  .common-cta-down-text {
    width: 100%;
    margin: 0 auto;
  }
  .in-large {
    justify-content: flex-end !important;
  }
  .next-section-line-wrap {
    margin: 4rem 0 0;
  }
}

/***** About our team Section *****/

section.about-team-wrapper {
  padding: 10rem 0 3rem;
}

section.about-team-wrapper .about-team-header {
  padding: 5rem 0 0;
}

section.about-team-wrapper .about-team-header h2 {
  font-family: var(--font-bold);
  font-size: 4.8rem;
  margin-bottom: 2rem;
}

section.about-team-wrapper .about-team-header h6 {
  font-family: var(--font-regular);
  font-size: 1.6rem;
  line-height: 1.8;
  max-width: 85%;
}

.abtteam-usp-points {
  padding: 0;
}

.abtteam-usp-points .abtteam-item {
  margin: 8rem 0;
}

.abtteam-usp-points .abtteam-item.down {
  margin-top: 18rem;
}

.abtteam-usp-points .abtteam-item h4 {
  font-family: var(--font-bold);
  font-size: 2.4rem;
  margin: 1.5rem 0;
}

.abtteam-usp-points .abtteam-item h6 {
  font-size: 1.6rem;
  color: var(--black-21);
  margin: 0;
  max-width: 90%;
}

.abtteam-culture .abtteam-culture-header {
  text-align: center;
}

.abtteam-culture .abtteam-culture-header h2 {
  font-family: var(--font-bold);
  font-size: 4.8rem;
  margin-bottom: 2rem;
}

.abtteam-culture .abtteam-culture-header h4 {
  font-family: var(--font-bold);
  font-size: 2.4rem;
  margin: 0;
}

.abtteam-culture .abtteam-culture-header h6 {
  font-size: 1.6rem;
  max-width: 60%;
  margin: 1.5rem auto;
}

.abtteam-culture .abtteam-culture-points {
  width: 100%;
  max-width: 80%;
  margin: 7rem auto 3rem;
}

.abtteam-culture .abtteam-culture-points .row > * {
  padding: 0;
}

.abtteam-culture .abtteam-culture-points .abtteam-cul-item-image {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border: 1px solid var(--light-75);
  padding: 3rem 0 0;
  min-height: 22.4rem;
  margin-left: -0.1rem;
}

.abtteam-culture .abtteam-culture-points .abtteam-cul-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 3rem;
}

.abtteam-culture .abtteam-culture-points .abtteam-cul-item.right {
  text-align: right;
}

.abtteam-culture .abtteam-culture-points .abtteam-cul-item h5 {
  font-family: var(--font-bold);
  font-size: 1.6rem;
  margin-bottom: 1.25rem;
}

.abtteam-culture .abtteam-culture-points .abtteam-cul-item p {
  font-size: 1.6rem;
  margin: 0;
  line-height: 1.65;
}

.abtteam-achievements {
  padding: 6rem 0;
}

.abtteam-achievements h2 {
  font-family: var(--font-bold);
  font-size: 4.8rem;
  margin-bottom: 3rem;
}

.abtteam-achievements ul li {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 1.5rem 0;
}

.abtteam-achievements ul li .abach-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  min-width: 4.8rem;
  min-height: 4.8rem;
  max-width: 4.8rem;
  max-height: 4.8rem;
  background: var(--bg);
}

.abtteam-achievements ul li h6 {
  font-family: var(--font-medium);
  font-size: 1.6rem;
  margin: 0;
}

.why-join-section {
  padding: 4rem 0 0;
}

.why-join-section h2 {
  font-family: var(--font-bold);
  font-size: 4.8rem;
  margin-bottom: 1.25rem;
}

.why-join-section h5 {
  font-family: var(--font-regular);
  font-size: 1.6rem;
  max-width: 50%;
  line-height: 1.6;
  margin: 0;
}

.why-join-section .why-join-items-wrap {
  margin: 4rem 0 0;
}

.why-join-section .why-join-items-wrap .wjs-item {
  max-width: 75%;
}

.why-join-section .why-join-items-wrap .wjs-item .wjs-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;
  max-width: 2.4rem;
  max-height: 2.4rem;
  margin-left: 0.25rem;
}

.why-join-section .why-join-items-wrap .wjs-item .wjs-img img {
  max-width: auto;
  max-height: 100%;
}

.why-join-section .why-join-items-wrap .wjs-item h6 {
  font-family: var(--font-bold);
  font-size: 2rem;
  margin: 1.75rem 0 0.75rem;
}

.why-join-section .why-join-items-wrap .wjs-item p {
  font-size: 1.6rem;
  margin: 0;
  line-height: 1.65;
}

.join-us-wrapper {
  margin: 8rem 0 0;
  padding: 3.2rem;
  border: 1px solid var(--light-75);
}

.join-us-wrapper h2 {
  font-family: var(--font-bold);
  font-size: 4.8rem;
  margin-bottom: 1.25rem;
}

.join-us-wrapper h6 {
  font-size: 1.6rem;
  margin-bottom: 0;
  max-width: 70%;
}

.join-us-wrapper button {
  margin: 2.5rem 0 1rem;
}

.join-us-wrapper a {
  display: inline-block;
}

/***** End of About our team Section *****/

/***** Team Section *****/

.team-ceo-section img {
  max-width: 100%;
}

/* Intro Section */

.team-ceo-section {
  padding: 8em 0 0;
}

.team-ceo-section .team-ceo-img-prof img {
  width: 350px;
}

.team-ceo-section .team-ceo-text-wrap h3 {
  font-size: 26px;
  color: #523695;
  margin-bottom: 5px;
}

.team-ceo-section .team-ceo-text-wrap h3 span {
  font-family: var(--font-regular);
}

.team-ceo-section .team-ceo-text-wrap h4 {
  position: relative;
  font-family: var(--font-regular);
  font-size: 20px;
  margin-bottom: 30px;
}

.team-ceo-section .team-ceo-text-wrap h4::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 56px;
  height: 2px;
  background-image: linear-gradient(to right, #8d6eb1 3%, #523695);
}

.team-ceo-section .team-ceo-text-wrap h4 span {
  font-family: var(--font-bold);
  color: #523695;
}

.team-ceo-section .team-ceo-text-wrap h6 {
  font-family: var(--font-medium);
  font-size: 14px;
  color: var(--light-75);
}

.team-ceo-section .team-ceo-text-wrap p {
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--black);
  margin: 15px 0;
  max-width: 90%;
}

/* End of Intro Section */

/* USP Section */

.team-ceo-section .team-ceo-usp {
  padding: 30px 0;
}

.team-ceo-section .team-ceo-usp .team-ceo-usp-img img {
  width: 400px;
}

.team-ceo-section .team-ceo-usp .team-ceo-usp-points {
  padding: 0;
  margin: 0;
}

.team-ceo-section .team-ceo-usp .team-ceo-usp-points .tcusp-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  background: #f5f5f5;
  border-radius: 50%;
}

.team-ceo-section .team-ceo-usp .team-ceo-usp-points .tcusp-img img {
  max-width: 20px;
}

.team-ceo-section .team-ceo-usp .team-ceo-usp-points .accordion-item {
  border: none;
  border-bottom: 1px solid #e0e0e0;
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-header {
  border: none;
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-header
  .accordion-button {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-inline: 0;
  padding-block: 12px;
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--black);
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  font-family: var(--font-bold);
  font-size: 20px;
  color: #523695;
  box-shadow: none;
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed):focus {
  border-bottom: none;
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed)
  .tcusp-img {
  background: #523695;
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  background: none;
  border-bottom: none !important;
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-body {
  padding-left: 60px;
  padding-top: 0;
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-body
  p {
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--black);
  line-height: 22px;
  margin: 0;
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-button:not(.collapsed)::after {
  background: none;
}

.team-ceo-section
  .team-ceo-usp
  .team-ceo-usp-points
  .accordion-item
  .accordion-button::after {
  background: none;
}

/* End of USP Section */

/* Social Service */

.team-ceo-section .team-ceo-social-service {
  padding: 12px 8px 12px 20px;
  background: var(--light-fa);
  border-radius: 24px;
  margin-bottom: 30px;
}

.team-ceo-section .team-ceo-social-service h4 {
  position: relative;
  font-family: var(--font-regular);
  font-size: 18px;
  margin: 15px 0 30px;
}

.team-ceo-section .team-ceo-social-service h4::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 56px;
  height: 2px;
  background-image: linear-gradient(to right, #8d6eb1 3%, #523695);
}

.team-ceo-section .team-ceo-social-service h4 span {
  font-family: var(--font-bold);
  color: #523695;
}

.team-ceo-section .team-ceo-social-service p {
  font-size: 14px;
  color: var(--black);
  line-height: 20px;
  max-width: 90%;
}

.team-ceo-section .team-ceo-social-service .team-ceo-ss-slider-wrap {
  background: var(--white);
  padding: 20px;
  border-radius: 24px;
  min-height: 300px;
}

.team-ceo-section .team-ceo-ss-slider-wrap h5 {
  font-family: var(--font-bold);
  font-size: 18px;
  margin: 0;
}

.team-ceo-section .team-ceo-ss-slider-wrap span {
  font-family: var(--font-bold);
  font-size: 10px;
  color: #bdbdbd;
}

.team-ceo-section .team-ceo-ss-slider-wrap p {
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--black);
  line-height: 24px;
  margin: 15px 0;
  max-width: 100%;
}

.team-ceo-section .team-ceo-ss-slider-wrap .owl-dots {
  margin-top: 40px !important;
}

.team-ceo-section .team-ceo-ss-slider-wrap .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  background: #d6d6d6;
}

.team-ceo-section .team-ceo-ss-slider-wrap .owl-dots .owl-dot.active span {
  width: 32px;
  background: #523695;
}

/* End of Social Service */

/* CSR */

.team-ceo-section .team-ceo-csr {
  background: #fff8eb;
  padding: 0;
  border-radius: 24px;
}

.team-ceo-section .team-ceo-csr .tc-csr-text {
  padding: 30px 24px;
}

.team-ceo-section .team-ceo-csr h4 {
  position: relative;
  font-family: var(--font-regular);
  font-size: 18px;
  margin-bottom: 15px;
}

.team-ceo-section .team-ceo-csr h4 span {
  font-family: var(--font-bold);
  color: #523695;
}

.team-ceo-section .team-ceo-csr p {
  font-size: 14px;
  color: var(--black);
  line-height: 22px;
  max-width: 95%;
  margin: 0;
}

/* End of CSR */

/* Last Section CTA */

.team-ceo-section .team-ceo-lastcta {
  margin: 30px 0;
}

.team-ceo-section .team-ceo-lastcta .team-ceo-lc-join {
  background: var(--light-fa);
  padding: 20px;
  border-radius: 24px;
}

.team-ceo-section .team-ceo-lastcta .team-ceo-lc-join h4 {
  position: relative;
  font-family: var(--font-regular);
  font-size: 18px;
  margin: 20px 0;
  text-align: center;
}

.team-ceo-section .team-ceo-lastcta .team-ceo-lc-join h4::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 56px;
  height: 2px;
  background-image: linear-gradient(to right, #8d6eb1 3%, #523695);
}

.team-ceo-section .team-ceo-lastcta .team-ceo-lc-join h4 span {
  font-family: var(--font-bold);
  color: #523695;
}

.team-ceo-section .team-ceo-lastcta .team-ceo-lc-join p {
  font-size: 14px;
  color: var(--black);
  line-height: 22px;
  text-align: center;
  margin: 40px 0 0;
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-text {
  padding: 30px 20px;
  border-radius: 24px;
  background: var(--light-fa);
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-text h4 {
  position: relative;
  font-family: var(--font-regular);
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-text h4::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 56px;
  height: 2px;
  background-image: linear-gradient(to right, #8d6eb1 3%, #523695);
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-text h4 span {
  font-family: var(--font-bold);
  color: #523695;
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-text p {
  font-size: 14px;
  color: var(--black);
  text-align: center;
  margin: 20px auto 0;
  max-width: 90%;
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-connect {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  padding: 20px;
  border-radius: 24px;
  background: var(--light-fa);
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-connect span {
  font-family: var(--font-regular);
  font-size: 12px;
  color: var(--black);
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-connect h4 {
  position: relative;
  font-family: var(--font-bold);
  font-size: 20px;
  color: #523695;
  margin: 5px 0 30px;
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-connect h4::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 56px;
  height: 2px;
  background-image: linear-gradient(to right, #8d6eb1 3%, #523695);
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-connect button {
  color: var(--black);
}

.team-ceo-section .team-ceo-lastcta .tc-lcta-connect button:hover {
  color: var(--white);
}

/* End of Last Section CTA */

@media only screen and (min-width: 300px) and (max-width: 900px) {
  /* About our team */

  section.about-team-wrapper {
    padding: 5rem 0;
  }

  section.about-team-wrapper .about-team-header h6 {
    max-width: 100%;
  }

  section.about-team-wrapper .about-team-header .abt-team-image {
    padding: 1rem;
  }

  section.about-team-wrapper .about-team-header .abt-team-image img {
    max-width: 100%;
    margin-top: 3rem;
  }

  section.about-team-wrapper img {
    max-width: 100%;
  }

  .abtteam-usp-points .abtteam-item {
    margin: 5rem 0;
  }

  .abtteam-usp-points .abtteam-item.down {
    margin: 0;
  }

  .abtteam-culture .abtteam-culture-points {
    max-width: 100%;
    margin: 4rem 0;
  }

  section.about-team-wrapper h2 {
    font-size: 2.5rem;
  }

  .abtteam-achievements {
    padding: 5rem 0 2rem;
  }

  .abtteam-culture .abtteam-culture-header h2 {
    font-size: 3rem;
  }

  .abtteam-culture-points .abtteam-culture .abtteam-culture-header h2 {
    font-size: 3rem;
  }

  .abtteam-culture .abtteam-culture-header h4 {
    font-size: 1.75rem;
  }

  .abtteam-culture .abtteam-culture-header h6 {
    max-width: 100%;
  }

  .abtteam-culture-points .row:nth-child(2),
  .abtteam-culture-points .row:last-child {
    flex-direction: column-reverse;
  }

  .abtteam-culture .abtteam-culture-points .abtteam-cul-item {
    padding: 3rem 1.25rem;
    text-align: left;
  }

  .abtteam-culture .abtteam-culture-points .abtteam-cul-item.right {
    text-align: left;
  }

  .abtteam-ach-image {
    margin: 0 0 3rem 0;
  }

  .why-join-section h5 {
    max-width: 100%;
  }

  .why-join-section .why-join-items-wrap .wjs-item {
    max-width: 100%;
    margin: 2rem 0;
  }

  .join-us-wrapper {
    margin-top: 3rem;
    padding: 2rem;
  }

  .join-us-wrapper h6 {
    max-width: 100%;
  }

  .join-us-wrapper img.join-team-img {
    max-width: 100%;
    margin: 2rem 0 0;
  }

  /* Meet our CEO */

  .team-ceo-section .team-ceo-lastcta .tc-lcta-connect {
    flex-direction: column;
    align-items: flex-start;
  }
}

/***** End of Team Section *****/

/* Services Page Styles */

.services-section {
  padding: 9em 0 0;
}

.services-section .services-header .serv-head-text {
  padding: 3.2rem;
  background: var(--white);
  box-shadow: 16px 16px 32px 0 rgba(0, 0, 0, 0.08);
  transform: translateX(10rem);
}

.services-section .services-header .serv-head-text h2 {
  font-family: var(--font-bold);
  font-size: 4rem;
  color: var(--black-21);
  margin: 0;
}

.services-section .services-header .serv-head-text h6 {
  font-family: var(--heading-regular);
  font-size: 1.6rem;
  padding: 0;
  margin: 1rem 0 0;
  line-height: 1.5;
}

.services-section .services-header .serv-head-text button {
  margin: 2rem 0;
}

.services-section .services-header .serv-head-text h6 span {
  font-size: 32px;
}

.services-section .services-header .serv-head-text h6 span[data-purple] {
  font-family: var(--heading-bold);
  color: var(--purple);
}

.services-section .services-header .serv-head-text h6 span[data-light-purple] {
  font-family: var(--heading-bold);
  color: var(--purple-light);
}

.services-section .services-header img.services-img {
  max-width: 100%;
  margin-right: -0.75rem;
}

.services-section .services-header button.contact-us-btn {
  font-family: var(--font-regular);
  font-size: 16px;
  padding: 4px 0;
  margin: 30px 0 0;
  color: var(--black);
  background: none;
  border: none;
  border-bottom: 2px solid var(--black);
  border-radius: 0px;
  transition: 0.1s ease-in-out all;
}

.services-section .services-header button.contact-us-btn:hover {
  background: var(--black);
  color: var(--white);
  padding: 6px 15px;
}

.services-section .services-header button.contact-us-btn img {
  transition: 0.2s ease-in-out all;
  width: 16px;
}

.services-section .services-header button.contact-us-btn:hover img {
  transform: translateX(5px);
}

.services-section .services-content {
  padding: 4em 0;
}

.services-section .services-content h6 {
  font-size: 16px;
  color: var(--black);
  line-height: 32px;
}

.services-section .services {
  padding: 2rem 0 0;
}

.services-section .services-item-wrap {
  padding: 2.4rem;
}

.services-section .services-item-wrap .services-item:not(:last-child) {
  border-bottom: 1px solid var(--light-e0);
  margin: 1.5rem 0;
  padding: 1.25rem 0;
}

.services-section .services-item-wrap .services-item span {
  font-family: var(--font-bold);
  font-size: 1.4rem;
  color: var(--light-9e);
}

.services-section .services-item-wrap .services-item h4 {
  font-family: var(--font-bold);
  font-size: 3.2rem;
  color: var(--black-21);
  margin: 1rem 0 2rem;
}

.services-section
  .services-item-wrap
  .services-item
  .services-desc:not(:last-child) {
  padding: 0 0 2rem 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--light-e0);
}

.services-section .services-item-wrap .services-item .services-desc h5 {
  font-family: var(--font-bold);
  font-size: 1.6rem;
  color: var(--purple);
  margin-bottom: 1.25rem;
}

.services-section .services-item-wrap .services-item .services-desc h6 {
  font-size: 1.6rem;
  color: var(--black-21);
  margin-bottom: 1.25rem;
}

/* Custom Services Tabs */

.nav.nav-tabs#servicesTab {
  padding: 0;
  margin: 6rem 0;
  border: none;
}

.nav.nav-tabs#servicesTab .nav-item {
  width: 100%;
  padding: 0;
}

.nav.nav-tabs#servicesTab .nav-item .nav-link {
  font-family: var(--font-black);
  font-size: 6.4rem;
  text-transform: uppercase;
  color: var(--light-d6);
  padding: 4rem 0;
  border: none;
  border-right: 1px solid var(--purple);
  border-bottom: 1px solid var(--light-e0) !important;
  border-radius: 0px;
  cursor: pointer;
  box-shadow: none !important;
}

.nav.nav-tabs#servicesTab .nav-item .nav-link:hover,
.nav.nav-tabs#servicesTab .nav-item .nav-link:focus {
  border-bottom: 1px solid var(--purple) !important;
  color: var(--black-21);
}

.nav.nav-tabs#servicesTab .nav-item .nav-link.active {
  border-top: 1px solid var(--light-e0) !important;
  border-bottom-color: var(--purple) !important;
  color: var(--black-21);
}

.nav.nav-tabs#servicesTab .nav-item .nav-link.active.first-child {
  border-top: none !important;
}

.nav.nav-tabs#servicesTab .nav-item .nav-link.last-child,
.nav.nav-tabs#servicesTab .nav-item .nav-link.active.last-child {
  border-bottom: none !important;
}

/* End of Custom Services Tabs */

/* Media Queries */

@media only screen and (max-width: 767px) {
  .services-section .services-header .serv-head-text {
    transform: translateX(0);
    padding: 1.25rem;
    box-shadow: none;
  }
  .services-section .services-header img.services-img {
    margin: 2rem 0 0;
  }
  .nav.nav-tabs#servicesTab {
    margin: 0;
  }
  .nav.nav-tabs#servicesTab .nav-item .nav-link {
    font-size: 3rem;
    padding: 1rem 0;
  }
  .services-section .services-item-wrap .services-item {
    padding: 1rem 0;
    margin: 0;
  }
  .services-section .services-item-wrap .services-item:not(:last-child) {
    padding: 1.25rem 0;
    margin: 0;
  }
  .services-section .services-item-wrap {
    padding: 1rem 0;
  }
  .services-section .services-item-wrap .services-item h4 {
    font-size: 2.25rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .services-section {
    padding-top: 7rem;
  }
  .services-section .services-header .serv-head-text {
    transform: translate(0);
    box-shadow: none;
    padding: 2.5rem 2rem 2.5rem 1.25rem;
  }
  .services-section .services-header img {
    position: relative;
    margin: 0;
  }
  .services-section .services-header h6 {
    margin-bottom: 20px;
  }
  .nav.nav-tabs#servicesTab {
    margin: 0;
  }

  .nav.nav-tabs#servicesTab .nav-item .nav-link {
    font-size: 3rem;
    padding: 1rem 0;
  }

  .services-section .services-item-wrap .services-item {
    padding: 1rem 0;
    margin: 0;
  }

  .services-section .services-item-wrap .services-item:not(:last-child) {
    padding: 1.25rem 0;
    margin: 0;
  }

  .services-section .services-item-wrap {
    padding: 1rem 0;
  }
  .services-section .services-item-wrap .services-item h4 {
    font-size: 2.25rem;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {
  .services-section .services-header .serv-head-text {
    transform: translateX(5rem);
  }
}

@media only screen and (min-width: 1600px) {
  .services-section .services-header .serv-head-text {
    transform: translateX(17rem);
    max-width: 70rem;
  }
}
